import { Spin } from 'antd'
import React from 'react'

export default function SuspenseLoader() {
    return (
        <div className='height-100vh d-flex align-item-center justify-content-center'>
            <Spin size='large'></Spin>
        </div>
    )
}

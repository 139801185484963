import React, { useEffect, useState } from 'react'
import {
    DatePicker,
    Space,
    Table,
    Typography,
    Button,
} from "antd";
import { CSVLink } from "react-csv";
import { useAtom } from "jotai";
import { apiClient, ranges } from '../../../services/http.helper';
import { IconDownload, IconPdf } from '@tabler/icons-react';
import { business_id } from '../../../storage';

const ledgerHeaders = [
    { label: "Note", key: "ledger_note" },
    { label: "Date", key: "ledger_date" },
    { label: "Credit", key: "credit" },
    { label: "Debit", key: "debit" },
    { label: "Balance", key: "balance" },
];
const { RangePicker } = DatePicker;
export default function LedgerComponent({ party }) {
    const [businessId] = useAtom(business_id);
    const [partyLedgerList, setPartyLedgerList] = useState([]);
    const [isPdfDownloading, setIsPdfDownload] = useState(false);
    const [datefilter, setDatefilter] = useState();
    useEffect(() => {
        const getPartyLedger = async () => {
            try {
                var obj = {
                    id: party.id,
                    business_id: businessId,
                };
                if (datefilter) {
                    obj.ledger_filter = { range: datefilter }
                }
                const result = await apiClient.post("api/web/parties/ledger", obj);
                if (result.status) {
                    const data = result.data.ledger.map((ledger) => ({ ...ledger, key: ledger.id }));
                    setPartyLedgerList(data);
                }
            } catch (error) {
                console.log("Error", error);
            }
        };
        getPartyLedger();
    }, [party, datefilter])

    const ledgerColumns = [
        {
            title: "#",
            dataIndex: "party",
            key: "number",
            render: (text, record, index) => "#" + (index + 1),
        },
        {
            title: "Date",
            dataIndex: "ledger_date",
            render: (text) => (
                <span className="font-weight-medium font-size-14">{text}</span>
            ),
        },
        {
            title: "Entries",
            dataIndex: "ledger_note",
            render: (text) => (
                <span className="font-weight-medium font-size-14">{text}</span>
            ),
        },
        {
            title: "Document Number	",
            dataIndex: "document_number",
            render: (text) => (
                <span className="font-weight-medium font-size-14">{text}</span>
            ),
        },
        {
            title: "Credit",
            dataIndex: "credit",
            render: (text) => (
                <span className="font-weight-medium font-size-14">{text}</span>
            ),
        },
        {
            title: "Debit",
            dataIndex: "debit",
            render: (text) => (
                <span className="font-weight-medium font-size-14">{text}</span>
            ),
        },
        {
            title: "Balance",
            dataIndex: "balance",
            render: (text) => (
                <span
                    className="font-weight-medium font-size-14"
                    style={{ color: `${text < 0 ? "red" : "green"}` }}
                >
                    {text}
                </span>
            ),
        },
    ];

    const handleRangePickerChange = (dates) => {
        setDatefilter(dates);
    };

    const downloadPDF = async () => {
        try {
            setIsPdfDownload(true);
            var obj = {
                id: party.id,
                business_id: businessId,
                ledger_filter: { range: datefilter },
            };
            const result = await apiClient.post("api/web/parties/ledger-pdf", obj, {
                responseType: "arraybuffer",
            });
            const arrayBuffer = result;
            const blob = new Blob([arrayBuffer], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download =
                party.party_name.replace(/[^a-zA-Z0-9]/g, "-").toLowerCase() +
                `-` +
                new Date().getTime() +
                ".pdf";

            // Step 3: Trigger a click on the download link
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
            setIsPdfDownload(false);
        } catch (error) {
            setIsPdfDownload(false);
            errorNotification(
                JSON.stringify(object),
                "Customers",
                JSON.stringify(error)
            );
        }
    };
    return (
        <div className="w-100 d-flex flex-column gap-10">
            <div className="d-flex justify-content-between flex-wrap gap-10">
                <div>
                    <Typography
                        style={{ color: "red", fontWeight: "600", marginBottom: "6px" }}
                    >{`You ${party?.balance?.balance < 1 ? "Collect" : "Pay"} ₹${Math.abs(party?.balance?.balance) || 0}`}</Typography>

                    <RangePicker
                        onChange={handleRangePickerChange}
                        style={{ width: 300 }}
                        className="mw-100"
                        value={datefilter}
                        presets={Object.keys(ranges).map((value, index) => ({
                            label: value,
                            value: ranges[value],
                        }))}
                    />
                </div>
                <Space>
                    <CSVLink
                        filename={`${party?.party_name}.csv`}
                        data={partyLedgerList}
                        headers={ledgerHeaders}
                        target="_blank"
                    >
                        <Button
                            className="icon-btn"
                            type="primary"
                            icon={<IconDownload size={16} />}
                        >
                            Ledger
                        </Button>
                    </CSVLink>
                    <Button
                        danger
                        className="icon-btn"
                        type="primary"
                        icon={<IconPdf size={16} />}
                        onClick={downloadPDF}
                        loading={isPdfDownloading}
                    >
                        View PDF
                    </Button>
                </Space>
            </div>
            <div className="w-100">
                <Table
                    columns={ledgerColumns}
                    dataSource={partyLedgerList}
                    size="small"
                    rowKey={"id"}
                    pagination={{
                        total: partyLedgerList.length,
                        defaultPageSize: 5
                    }}
                    scroll={{ x: "max-content" }}
                />
            </div>
        </div>
    )
}

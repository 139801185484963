import { message } from "antd";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import { useEffect, useMemo } from "react";
import { apiClient, config } from "../services/http.helper";
import { business_id } from "../storage";
import {
  business_data,
  business_signature,
  chargeDeleteList,
  invoice,
  invoiceAddCharges,
  invoiceAttachments,
  invoiceDeleteList,
  invoiceParty,
  invoiceProductList,
  invoiceSetting,
  partyClosingBalance,
  productDummy,
  userBanksList,
} from "../storage/invoiceStore";
import { errorNotification } from "./notification.helper";

const useInvoiceCalculation = (
  invoiceProduct,
  additionalCharges,
  invoiceData,
  setInvoiceData,
  fullyPaid,
) => {
  useMemo(() => {
    let total = 0;
    let total_discount = 0;
    let total_tax = 0;
    let total_taxable_amount = 0;
    invoiceProduct.map((product) => {
      total += parseFloat(product.product_amount);
      var price = product.product_sell_price * product.product_quantity;
      var disc_amt = 0;
      var discount = product.product_discount || 0;
      if (product.discount_type === "RS") {
        disc_amt = discount * product.product_quantity;
        total_discount += disc_amt;
        total_taxable_amount += price - disc_amt;
      } else {
        disc_amt = (price * discount) / 100;
        total_discount += disc_amt;
        total_taxable_amount += price - disc_amt;
      }
      total_tax += product.product_tax;
    });

    let totalCharges = 0;
    additionalCharges.map((field) => {
      if (field.chargeValue) {
        totalCharges += parseFloat(field.chargeValue);
      }
    });
    total = total + totalCharges;
    let extraDiscount = parseFloat(invoiceData.additional_discount).toFixed(2);
    if (invoiceData.additional_discount) {
      total = total - extraDiscount;
    }
    let obj = invoiceData;
    total_discount += Number(obj.additional_discount) ? obj.additional_discount : 0;
    obj.total_amount = total ?? 0;
    obj.total_tax = (total_tax ?? 0).toFixed(2);
    obj.total_discount = (total_discount ?? 0).toFixed(2);
    obj.taxable_amount = (total_taxable_amount ?? 0).toFixed(2);
    if (invoiceData.autoRoundOffChecked) {
      let prev_amount = obj.total_amount;
      obj.total_amount = Math.round(obj.total_amount);
      obj.roundOffAmount = (obj.total_amount - prev_amount).toFixed(2);
    } else {
      obj.roundOffAmount = 0;
    }
    if (obj.is_tds == "1" && obj.tds_details?.tax) {
      const { taxable_amount, tds_details } = obj;
      const total_tds = (taxable_amount * tds_details?.tax) / 100;
      tds_details.tds_amount = total_tds.toFixed(2);
    }
    if (obj.is_tcs == "1" && obj.tcs_details?.tax) {
      const { taxable_amount, tcs_details } = obj;
      const total_tcs = (taxable_amount * tcs_details?.tax) / 100;
      tcs_details.tcs_amount = total_tcs.toFixed(2);
      obj.total_amount += total_tcs;
    }
    if (fullyPaid) {
      obj.received_amount = obj.total_amount;
    }
    setInvoiceData(obj);
  }, [invoiceProduct, additionalCharges, invoiceData, setInvoiceData]);
};

const useInitializeCreateInvoice = (type, action) => {
  const [invoiceData, setInvoiceData] = useAtom(invoice);
  const [, setProductDummy] = useAtom(productDummy);
  const [, setInvoiceProductList] = useAtom(invoiceProductList);
  const [, setInvoiceSettingData] = useAtom(invoiceSetting);
  const [businessId] = useAtom(business_id);
  const [, setBusinessData] = useAtom(business_data);
  const [, setSignature] = useAtom(business_signature);
  const [, setUserBanks] = useAtom(userBanksList);
  const resetInvoiceData = useResetAtom(invoice);
  const resetParty = useResetAtom(invoiceParty);
  const resetDeleteProductList = useResetAtom(invoiceDeleteList);
  const resetDeleteChargeList = useResetAtom(chargeDeleteList);
  const resetInvoiceProductList = useResetAtom(invoiceProductList);
  const resetAdditionalCharges = useResetAtom(invoiceAddCharges);
  const resetInvoiceAttachements = useResetAtom(invoiceAttachments);
  const resetUserBanks = useResetAtom(userBanksList);

  useEffect(() => {
    const fetchCreateData = async () => {
      try {
        const [businessData, invoiceSettingsData, banksData] = await Promise.all([
          businessdetail(),
          getInvoiceSettings(),
          getUserBanks(),
        ]);
        let invoiceObj = { ...invoiceData };
        invoiceObj.invoice_setting_id = invoiceSettingsData.id;
        invoiceObj.invoice_number = invoiceSettingsData.invoice_number;
        invoiceObj.custom_fields = invoiceSettingsData.custom_fields || [];
        invoiceObj.payment_mode = 'Cash';
        const bank = banksData.find((bank) => bank.isDefault === '1');
        if (bank) {
          invoiceObj.bank_id = bank.id
          if (bank.type !== 'Cash') {
            invoiceObj.account_holder_name = bank.account_holder_name;
            invoiceObj.account_number = bank.account_no;
            invoiceObj.account_type = bank.account_type;
            invoiceObj.bank_name = bank.bank_name;
            invoiceObj.ifsc_code = bank.ifsc_code;
            invoiceObj.upi_id = bank.upi_id;
          }
        } else {
          invoiceObj.bank_id = 0
        }
        const banks = banksData.map((item, index) => ({
          key: item.id, value: item.id, label: `${item.bank_name} (${item.account_no})`, data: item,
        }));
        const payment_bank = banks.find((item) => item.data.type === 'Cash');
        if (payment_bank) {
          invoiceObj.payment_bank = payment_bank.data;
        }
        setUserBanks(banks);
        invoiceObj.issue_date = new Date(
          dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
        );
        if (invoiceObj.due_date !== null) {
          let due = dayjs(invoiceObj.due_date);
          invoiceObj.due_date = new Date(due.format("YYYY-MM-DDTHH:mm:ssZ"));
        }
        invoiceObj.term_condition = invoiceSettingsData.setting_term_condition;
        invoiceObj.invoice_notes = invoiceSettingsData.setting_notes;
        const productDummyInitiate = [
          { ...invoiceSettingsData.pDummy },
          { ...invoiceSettingsData.pDummy },
          { ...invoiceSettingsData.pDummy },
        ];
        setInvoiceProductList(productDummyInitiate);
        invoiceObj.custom_fields.forEach((object) => {
          let field = {
            name: object.value,
            value: "",
          };
          invoiceSettingsData.pDummy.custom_fields_value.push(field);
        });
        setProductDummy(invoiceSettingsData.pDummy);
        delete invoiceSettingsData.pDummy;
        setInvoiceSettingData(invoiceSettingsData);
        setInvoiceData(invoiceObj);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const businessdetail = async () => {
      try {
        var obj = {
          business_id: businessId,
        };
        const result = await apiClient.post("api/web/business/detail", obj);
        if (result.status) {
          let businessData = result.data;
          setBusinessData(businessData);
          setSignature(businessData.signature);
          return businessData;
        } else {
          message.error("Error Occured", result.message);
        }
      } catch (error) {
        errorNotification(
          JSON.stringify(obj),
          "CreateInvoice",
          JSON.stringify(error),
        );
      }
    };

    const getInvoiceSettings = async () => {
      try {
        var obj = {
          business_id: businessId,
          invoice_type: type,
        };
        let setting_response = await apiClient.post(
          "/api/web/invoice/settings/",
          obj,
        );
        if (setting_response.status) {
          return setting_response.data;
        } else {
          message.error("Error Occured", setting_response.message);
        }
      } catch (error) {
        errorNotification(
          JSON.stringify(obj),
          "CreateInvoice",
          JSON.stringify(error),
        );
      }
    };

    const getUserBanks = async () => {
      try {
        const obj = {
          business_id: businessId
        }
        const result = await apiClient.post('api/web/business/banks-list', obj);
        if (result.status) {
          return result.data;
        }
      } catch (error) {
        errorNotification(
          JSON.stringify(obj),
          "getUserBanks",
          JSON.stringify(error),
        );
      }
    }

    const resetInvoice = () => {
      resetDeleteChargeList();
      resetAdditionalCharges();
      resetDeleteProductList();
      resetInvoiceData();
      resetInvoiceProductList();
      resetParty();
      resetInvoiceAttachements();
      resetUserBanks();
    };
    if (action === "create") {
      resetInvoice();
      fetchCreateData();
    }
    return () => resetInvoice();
  }, [businessId, action]);
};

const useInitializeEditInvoice = (type, invoice_id, action) => {
  const [, setInvoiceData] = useAtom(invoice);
  const [, setProductDummy] = useAtom(productDummy);
  const [, setInvoiceSettingData] = useAtom(invoiceSetting);
  const [, setBusinessData] = useAtom(business_data);
  const [businessId] = useAtom(business_id);
  const [, setSignature] = useAtom(business_signature);
  const [, setParty] = useAtom(invoiceParty);
  const [, setInvoiceProductList] = useAtom(invoiceProductList);
  const [, setAdditionalCharges] = useAtom(invoiceAddCharges);
  const [, setFileList] = useAtom(invoiceAttachments);
  const [, setPartyBalance] = useAtom(partyClosingBalance);
  const [, setUserBanks] = useAtom(userBanksList);
  useEffect(() => {
    const getInvoiceDetails = async () => {
      try {
        var obj = {
          business_id: businessId,
          invoice_type: type,
          invoice_id: invoice_id,
        };
        let detail_response = await apiClient.post(
          "/api/web/invoice/detail/",
          obj,
        );
        if (detail_response.status) {
          return detail_response.data;
        } else {
          message.error("Error Occured");
        }
      } catch (error) {
        errorNotification(
          JSON.stringify(obj),
          "CreateInvoice",
          JSON.stringify(error),
        );
      }
    };
    const fetchInvoiceDetail = async () => {
      try {
        const [detailData, invoiceSettingsData, banksData] = await Promise.all([
          getInvoiceDetails(),
          getInvoiceSettings(),
          getUserBanks(),
          businessdetail(),
        ]);
        var data = { ...detailData };
        setAdditionalCharges(data.additional_charges);
        var newInvoiceItems = data.invoice_items.map((rest) => {
          rest.product_unit_list = [];
          rest.amount_with_tax = rest.product_sell_price + rest.product_tax;
          rest.tax_value = fetchTaxValue(rest.product_gst);
          if (rest.products.secondary_unit) {
            rest.product_unit_list.push({
              label: rest.products.secondary_unit,
              value: rest.products.secondary_unit,
              rate: rest.products.conversion_rate,
            });
          }
          if (rest.products.secondary_unit) {
            rest.product_unit_list.push({
              label: rest.products.product_unit,
              value: rest.products.product_unit,
              rate: 1,
            });
          }
          rest.product_batch = [];
          if (rest.products.product_batch) {
            rest.product_batch = rest.products.product_batch;
          }
          rest.serial_number = [];
          rest.product_serial_numbers = [];
          if (
            rest.products.product_serial_numbers &&
            data.type !== "PURCHASEBILL"
          ) {
            rest.product_serial_numbers =
              rest.products.product_serial_numbers?.flatMap((value) => ({
                label: value.number,
                value: value.number,
                key: value.id,
                serial_id: value.id,
              }));
          }
          if (rest.map_serial_number) {
            rest.serial_number = rest.map_serial_number?.flatMap((value) => ({
              label: value.product_serial_number.number,
              value: value.product_serial_number.number,
              key: value.product_serial_number.id,
              serial_id: value.product_serial_number.id,
              map_id: value.id,
            }));
            rest.product_serial_numbers = rest.product_serial_numbers.concat(
              rest.serial_number,
            );
          }
          delete rest.products;
          return rest;
        });
        setInvoiceProductList(newInvoiceItems);
        delete data.invoice_items;
        setParty(data.party);
        setPartyBalance(data?.party.balance?.balance);
        delete data.party;
        let issue = dayjs(data.issue_date);
        data.issue_date = new Date(issue.format("YYYY-MM-DDTHH:mm:ssZ"));
        if (data.due_date !== null) {
          let due = dayjs(data.due_date);
          data.due_date = new Date(due.format("YYYY-MM-DDTHH:mm:ssZ"));
        }
        data.custom_fields = data.custom_fields || [];
        if (data.attachments && data.attachments?.length > 0) {
          setFileList(data.attachments.map((attach, index) => ({
            uid: `- ${index + 1}`,
            name: attach.file_original_name,
            status: "done",
            url: config.url.STORAGE_URL + "invoice_attachments/" + attach.file_name,
            file_name: attach.file_name,
            file_original_name: attach.file_original_name,
            id: attach.id
          })))
        }
        const banks = banksData.map((item, index) => ({
          key: item.id, value: item.id, label: `${item.bank_name} (${item.account_no})`, data: item,
        }));
        setUserBanks(banks);
        setInvoiceData(data);
        setProductDummy(invoiceSettingsData.pDummy);
        delete invoiceSettingsData.pDummy;
        setInvoiceSettingData(invoiceSettingsData);

      } catch (error) {
        console.log(error);
        errorNotification("", "Invoice", JSON.stringify(error));
      }
    };
    const businessdetail = async () => {
      try {
        var obj = {
          business_id: businessId,
        };
        const result = await apiClient.post("api/web/business/detail", obj);
        if (result.status) {
          let businessData = result.data;
          setBusinessData(businessData);
          setSignature(businessData.signature);
        } else {
          message.error("Error Occured", result.message);
        }
      } catch (error) {
        errorNotification(
          JSON.stringify(obj),
          "CreateInvoice",
          JSON.stringify(error),
        );
      }
    };

    const getInvoiceSettings = async () => {
      try {
        var obj = {
          business_id: businessId,
          invoice_type: type,
        };
        let setting_response = await apiClient.post(
          "/api/web/invoice/settings",
          obj,
        );
        if (setting_response.status) {
          return setting_response.data;
        } else {
          message.error("Error Occured", setting_response.message);
        }
      } catch (error) {
        errorNotification(
          JSON.stringify(obj),
          "CreateInvoice",
          JSON.stringify(error),
        );
      }
    };
    const getUserBanks = async () => {
      try {
        const obj = {
          business_id: businessId
        }
        const result = await apiClient.post('api/web/business/banks-list', obj);
        if (result.status) {
          return result.data;
        }
      } catch (error) {
        errorNotification(
          JSON.stringify(obj),
          "getUserBanks",
          JSON.stringify(error),
        );
      }
    }
    if (action === "edit") {
      fetchInvoiceDetail();
    }
  }, [businessId, action, type, invoice_id]);
};

const useInitializeConvertInvoice = (type, invoice_id, action) => {
  const [, setInvoiceData] = useAtom(invoice);
  const [, setProductDummy] = useAtom(productDummy);
  const [, setInvoiceSettingData] = useAtom(invoiceSetting);
  const [, setBusinessData] = useAtom(business_data);
  const [businessId] = useAtom(business_id);
  const [, setSignature] = useAtom(business_signature);
  const [, setParty] = useAtom(invoiceParty);
  const [, setInvoiceProductList] = useAtom(invoiceProductList);
  const [, setAdditionalCharges] = useAtom(invoiceAddCharges);
  const [, setFileList] = useAtom(invoiceAttachments);


  useEffect(() => {
    const getInvoiceDetails = async () => {
      try {
        var obj = {
          business_id: businessId,
          invoice_id: invoice_id,
        };
        let detail_response = await apiClient.post(
          "/api/web/invoice/detail/",
          obj,
        );
        if (detail_response.status) {
          return detail_response.data;
        } else {
          message.error("Error Occured", detail_response.message);
        }
      } catch (error) {
        errorNotification(
          JSON.stringify(obj),
          "CreateInvoice",
          JSON.stringify(error),
        );
      }
    };
    const fetchInvoiceDetail = async () => {
      try {
        const [detailData, invoiceSettingsData, businessData] =
          await Promise.all([
            getInvoiceDetails(),
            getInvoiceSettings(),
            businessdetail(),
          ]);
        let obj = { ...detailData };
        delete obj.id;
        var newAdditionalCharges = obj.additional_charges.map((object) => {
          var { id, ...rest } = object; // Use destructuring to exclude 'id' key
          return rest;
        });
        var newInvoiceItems = obj.invoice_items.map((object) => {
          var { id, ...rest } = object; // Use destructuring to exclude 'id' key
          rest.product_unit_list = [];
          if (rest.products.secondary_unit) {
            rest.product_unit_list.push({
              label: rest.products.secondary_unit,
              value: rest.products.secondary_unit,
              rate: rest.products.conversion_rate,
            });
          }
          if (rest.products.product_unit) {
            rest.product_unit_list.push({
              label: rest.products.product_unit,
              value: rest.products.product_unit,
              rate: 1,
            });
          }
          delete rest.products;
          return rest;
        });
        delete obj.invoice_items;
        obj.account_holder_name = businessData.account_holder_name;
        obj.account_number = businessData.account_number;
        obj.account_type = businessData.account_type;
        obj.bank_name = businessData.bank_name;
        obj.ifsc_code = businessData.ifsc_code;
        obj.upi_id = businessData.upi_id;
        obj.invoice_setting_id = invoiceSettingsData.id;
        obj.invoice_number = invoiceSettingsData.invoice_number;
        obj.custom_fields = detailData.custom_fields || [];
        obj.po_number = detailData.invoice_number;
        obj.issue_date = new Date(dayjs().format("YYYY-MM-DDTHH:mm:ssZ"));
        if (obj.due_date !== null) {
          let due = dayjs(obj.due_date);
          obj.due_date = new Date(due.format("YYYY-MM-DDTHH:mm:ssZ"));
        }
        obj.term_condition = invoiceSettingsData.setting_term_condition;
        obj.invoice_notes = invoiceSettingsData.setting_notes;
        setAdditionalCharges(newAdditionalCharges);
        setInvoiceProductList(newInvoiceItems);
        setParty(obj.party);
        delete obj.party;

        setInvoiceData(obj);
        setProductDummy(invoiceSettingsData.pDummy);
        delete invoiceSettingsData.pDummy;
        setInvoiceSettingData(invoiceSettingsData);
      } catch (error) {
        errorNotification("", "Invoice", JSON.stringify(error));
      }
    };
    const businessdetail = async () => {
      try {
        var obj = {
          business_id: businessId,
        };
        const result = await apiClient.post("api/web/business/detail", obj);
        if (result.status) {
          let businessData = result.data;
          setBusinessData(businessData);
          setSignature(businessData.signature);
          return businessData;
        } else {
          message.error("Error Occured", result.message);
        }
      } catch (error) {
        errorNotification(
          JSON.stringify(obj),
          "CreateInvoice",
          JSON.stringify(error),
        );
      }
    };

    const getInvoiceSettings = async () => {
      try {
        var obj = {
          business_id: businessId,
          invoice_type: type,
        };
        let setting_response = await apiClient.post(
          "/api/web/invoice/settings/",
          obj,
        );
        if (setting_response.status) {
          return setting_response.data;
        } else {
          message.error("Error Occured");
        }
      } catch (error) {
        errorNotification(
          JSON.stringify(obj),
          "CreateInvoice",
          JSON.stringify(error),
        );
      }
    };
    if (action === "convert") {
      fetchInvoiceDetail();
    }
  }, [businessId, action, type, invoice_id]);
};
const fetchTaxValue = (gst) => {
  let tax_value;
  switch (gst) {
    case "0":
    case "NONE":
    case "exempted":
    case "0%":
      tax_value = 0;
      break;
    case "26":
      tax_value = 26;
      break;
    default:
      tax_value = parseFloat(gst);
  }
  return tax_value;
};

const fetchDocumentType = (paramType) => {
  if (paramType === "invoice") {
    return "SALESINVOICE";
  } else if (paramType === "quotations") {
    return "QUOTATION";
  } else if (paramType === "purchase-bill") {
    return "PURCHASEBILL";
  } else if (paramType === "delivery-challan") {
    return "DELIVERYCHALLAN";
  } else if (paramType === "credit-note") {
    return "SALESRETURN";
  } else if (paramType === "debit-note") {
    return "PURCHASERETURN";
  } else if (paramType === "purchase-order") {
    return "PURCHASEORDER";
  } else {
    return "SALESINVOICE";
  }
};

const fetchDocumentName = (docType) => {
  if (docType === "SALESINVOICE") {
    return "Sales Invoice";
  } else if (docType === "QUOTATION") {
    return "Quotation";
  } else if (docType === "PURCHASEBILL") {
    return "Purchase Bill";
  } else if (docType === "DELIVERYCHALLAN") {
    return "Delivery Challan";
  } else if (docType === "SALESRETURN") {
    return "Credit Note";
  } else if (docType === "PURCHASERETURN") {
    return "Debit Note";
  } else if (docType === "PURCHASEORDER") {
    return "Purchase Order";
  } else {
    return "Sales Invoice";
  }
};

const invoiceParams = {
  invoices: {
    title: "Tax Invoice",
    create: "Create Tax Invoice",
    update: "Update Tax Invoice",
    createUrl: "/document/invoices/create",
  },
  quotations: {
    title: "Quotation",
    create: "Create Quotation",
    update: "Update Quotation",
    createUrl: "/document/quotations/create",
  },
  credit_note: {
    title: "Credit Note",
    create: "Create Credit Note",
    update: "Update Credit Note",
    createUrl: "/document/credit-note/create",
  },
  delivery_challan: {
    title: "Delivery Challan",
    create: "Create Delivery Challan",
    update: "Update Delivery Challan",
    createUrl: "/document/delivery-challan/create",
  },
  purchase_bill: {
    title: "Purchase Bill",
    create: "Create Purchase Bill",
    update: "Update Purchase Bill",
    createUrl: "/document/purchase-bill/create",
  },
  debit_note: {
    title: "Debit Note",
    create: "Create Debit Note",
    update: "Update Debit Note",
    createUrl: "/document/debit-note/create",
  },
  purchase_order: {
    title: "Purchase Order",
    create: "Create Purchase Order",
    update: "Update Purchase Order",
    createUrl: "/document/purchase-order/create",
  },
};

const shareInvoicePdfWhatsapp = async (invoice) => {
  try {
    var obj = {
      invoice_id: invoice.id,
    };
    let document_response = await apiClient.post(
      "/api/web/invoice/generate-share-url",
      obj,
    );
    if (document_response.status) {
      let doc_name = fetchDocumentName(invoice.type);
      const message = `Hi, ${invoice.party.party_name}, Thanks for your business.\nHere's your ${doc_name} details recorded on ${invoice.issue_date}.\nTotal amount: Rs. ${invoice.total_amount}\nView Invoice here: https://app.billclap.com/preview/${invoice.id}/${document_response.data} \n Invoice generated on b₹llclap.`;
      // const message = `https://app.billclap.com/preview/${invoiceId}/${document_response.data}`;
      const whatsappUrl = `https://api.whatsapp.com://send?text=${encodeURIComponent(message)}`;
      window.open(whatsappUrl, "_blank");
    }
  } catch (error) {
    errorNotification(
      JSON.stringify(obj),
      "InvoiceListing",
      JSON.stringify(error),
    );
  }
};

const unitList = [
  {
    label: "BAGS (Bag)",
    value: "Bag",
    rate: 1,
  },
  {
    label: "BOTTLES (Btl)",
    value: "Btl",
    rate: 1,
  },
  {
    label: "BOX (Box)",
    value: "Box",
    rate: 1,
  },
  {
    label: "BUNDLES (Bdl)",
    value: "Bdl",
    rate: 1,
  },
  {
    label: "CANS (Can)",
    value: "Can",
    rate: 1,
  },
  {
    label: "CARTONS (Ctn)",
    value: "Ctn",
    rate: 1,
  },
  {
    label: "DOZENS (Dzn)",
    value: "Dzn",
    rate: 1,
  },
  {
    label: "GRAMS (Gm)",
    value: "Gm",
    rate: 1,
  },
  {
    label: "KILOGRAMS (Kg)",
    value: "Kg",
    rate: 1,
  },
  {
    label: "LITRE (Ltr)",
    value: "Ltr",
    rate: 1,
  },

  {
    label: "METERS (Mtr)",
    value: "Mtr",
    rate: 1,
  },
  {
    label: "MILILITRE (Ml)",
    value: "Ml",
    rate: 1,
  },
  {
    label: "NUMBERS (Nos)",
    value: "Nos",
    rate: 1,
  },
  {
    label: "PACKS (Pac)",
    value: "Pac",
    rate: 1,
  },

  {
    label: "PAIRS (Prs)",
    value: "Prs",
    rate: 1,
  },
  {
    label: "PIECES (Pcs)",
    value: "Pcs",
    rate: 1,
  },
  {
    label: "QUINTAL (Qtl)",
    value: "Qtl",
    rate: 1,
  },
  {
    label: "ROLLS (Rol)",
    value: "Rol",
    rate: 1,
  },

  {
    label: "SQUARE FEET (Sqft)",
    value: "Sqft",
    rate: 1,
  },
  {
    label: "SQUARE METERS (Sqm)",
    value: "Sqm",
    rate: 1,
  },
  {
    label: "TABLETS (Tbs)",
    value: "Tbs",
    rate: 1,
  },
];

const fetchDocPath = (docType) => {
  if (docType === "SALESINVOICE") {
    return "/document/invoices/";
  } else if (docType === "QUOTATION") {
    return "/document/quotations/";
  } else if (docType === "PURCHASEBILL") {
    return "/document/purchase-bill/";
  } else if (docType === "DELIVERYCHALLAN") {
    return "/document/delivery-challan/";
  } else if (docType === "SALESRETURN") {
    return "/document/credit-note/";
  } else if (docType === "PURCHASERETURN") {
    return "/document/debit-note/";
  } else if (docType === "PURCHASEORDER") {
    return "/document/purchase-order/";
  } else {
    return null;
  }
}

const paymentsModes = [
  {
    label: "Online",
    value: "Online",
  },
  {
    label: "Cash",
    value: "Cash",
  },
  {
    label: "UPI",
    value: "UPI",
  },
  {
    label: "Cheque",
    value: "Cheque",
  },
  {
    label: "Card",
    value: "Card",
  },
  {
    label: "Bank Transfer",
    value: "BankTransfer",
  },
];

const theme_colors = [
  {
    color: "#000000",
    backgroundColor: "#efefef",
    textColor: "#000000",
    colorName: "black",
  },
  {
    color: "#0b6a9f",
    backgroundColor: "#d9edf8",
    textColor: "#000000",
    colorName: "blue",
  },
  {
    color: "#407400",
    backgroundColor: "#dbefc3",
    textColor: "#000000",
    colorName: "green",
  },
  {
    color: "#c11111",
    backgroundColor: "#fadbdb",
    textColor: "#000000",
    colorName: "red",
  },
  {
    color: "#4A148C",
    backgroundColor: "#E1BEE7",
    textColor: "#000000",
    colorName: "purple",
  },
  {
    color: "#ff7b00",
    backgroundColor: "#FFE0B2",
    textColor: "#000000",
    colorName: "orange",
  },
  {
    color: "#283593",
    backgroundColor: "#E8EAF6",
    textColor: "#000000",
    colorName: "orange",
  },
];


export {
  fetchDocumentType,
  fetchTaxValue,
  invoiceParams,
  theme_colors,
  fetchDocPath,
  shareInvoicePdfWhatsapp,
  paymentsModes,
  unitList,
  useInitializeConvertInvoice,
  useInitializeCreateInvoice,
  useInitializeEditInvoice,
  useInvoiceCalculation
};


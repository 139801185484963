import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
  Row,
  Col,
  Collapse,
  Typography,
  DatePicker,
  TimePicker,
  Checkbox,
  message,
  Card,
} from "antd";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
const dateFormat = "YYYY-MM-DD";
const format = "HH:mm";

import { errorNotification } from "../../utils/notification.helper";
import { apiClient } from "../../services/http.helper";
import { business_id, user_access_token } from "../../storage";
import { useAtom } from "jotai";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const CreateCoupon = () => {
  const { Panel } = Collapse;
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [businessId] = useAtom(business_id);
  const [user] = useAtom(user_access_token);
  const [orderCondition, setOrderCondition] = useState("order_value");
  //   const [applyCouponOn, setApplyCouponOn] = useState("all_products");
  const [usesLimitPerCustomer, setUsesLimitPerCustomer] = useState("Unlimited");
  const [endDateSelect, setEndDateSelect] = useState();
  const [endDateCheckbox, setEndDateCheckbox] = useState(false);
  const [couponType, setCouponType] = useState(null);
  //   const [options,setOptions] = useState([]);

  const orderConditionHandler = (e) => {
    setOrderCondition(e.target.value);
  };

  //   const handleChange=(value)=>{
  //     console.log("value",value)
  //   }

  //   const applyCouponHandler = (value) => {
  //     setApplyCouponOn(value);
  //     if (value == "specific_products") {
  //       fetchProductList();
  //     }
  //     if (value == "specific_products") {
  //       fetchCategoryList();
  //     }
  //   };

  useEffect(() => {
    setCouponType(searchParams.get("id"));
  }, []);

  useEffect(() => {
    const fetchCouponDetails = async () => {
      try {
        var obj = {
          id,
          business_id: businessId,
        };
        const result = await apiClient.post("api/web/coupon/details", obj);
        if (result.status) {
          setCouponType(result.data.couponType);
          const dateFormat = "YYYY-MM-DD";
          const timeFormat = "HH:mm:ss"; // or any other appropriate format for time

          form.setFieldsValue({
            ...result.data,
            validFromDate: dayjs(result.data.validFromDate, dateFormat),
            validFromTime: dayjs(result.data.validFromTime, format),
            validToDate: dayjs(result.data.validToDate, dateFormat),
            validToTime: dayjs(result.data.validToTime, timeFormat),
          });
        } else {
          message.error(result.message);
        }
      } catch (error) {
        errorNotification(
          JSON.stringify(obj),
          "Shopcoupon",
          JSON.stringify(error)
        );
      }
    };

    if (id) fetchCouponDetails();
  }, [id, businessId]);

  const onFinish = async (values) => {
    setLoader(true);
    try {
      var object = {
        ...values,
      };
      object.validFromTime = dayjs(values.validFromTime).format(format);
      object.id = id;
      object.couponType = couponType;
      object.business_id = businessId;
      const result = await apiClient.post("api/web/coupon/create", object);
      if (result.status) {
        message.success("Create Coupon Successfully!!!");
        navigate(-1);
      } else {
        message.error("Failed");
      }
    } catch (error) {
      errorNotification(
        JSON.stringify(object),
        "Shopsettings",
        JSON.stringify(error)
      );
    } finally {
      setLoader(false);
    }
  };

  const handleSave = () => {
    form.submit();
  };
  const handleDelete = async () => {
    try {
      var obj = {
        id,
        business_id: businessId,
      };
      const result = await apiClient.post("api/web/coupon/delete", obj);
      if (result.status) {
        navigate(-1);
      } else {
        message.error(result.message);
      }
    } catch (error) {
      errorNotification(
        JSON.stringify(obj),
        "Shopcoupon",
        JSON.stringify(error)
      );
    }
  };

  return (
    <Card className="ant-card-layout height-90vh">
      <div className="">Coupon Details</div>
      <Form form={form} layout="vertical" onFinish={onFinish} size="large">
        <Row gutter={[16]}>
          <Col span={12}>
            <Form.Item
              label="Coupon code"
              name="couponCode"
              rules={[
                {
                  required: true,
                  message: "Coupon code is required !",
                },
              ]}
            >
              <Input
                placeholder="Enter coupon code (min 3 characters)"
                style={{ textTransform: "uppercase" }}
                maxLength={20}
                minLength={3}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Usage limit per customer"
              name="usageLimitPerCustomer"
              initialValue={usesLimitPerCustomer}
            >
              <Select
                placeholder="Select limit per customer"
                onChange={(value) => setUsesLimitPerCustomer(value)}
              >
                {/* <Select.Option value="only_once">Only once</Select.Option>
                <Select.Option value="custom">Custom</Select.Option> */}
                <Select.Option value="unlimited">Unlimited</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {usesLimitPerCustomer == "custom" && (
            <Col span={12}>
              <Form.Item label="Usage per customer" name="customUsageLimit">
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          )}
          {couponType == "2" && (
            <Col span={12}>
              <Form.Item
                label="Buy"
                name="buyItem"
                rules={[
                  {
                    required: true,
                    message: "Buy item is required !",
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          )}
          {couponType == "2" && (
            <Col span={12}>
              <Form.Item
                label="Get"
                name="getFreeItem"
                rules={[
                  {
                    required: true,
                    message: "get item is required !",
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          )}

          {couponType !== "2" && (
            <Col span={12}>
              <Form.Item
                label={`Discount ${couponType == "0" ? "percentage" : ""} ${couponType == "1" ? "amount" : ""}`}
                name="discount"
                rules={[
                  {
                    required: true,
                    message: "Discount is required !",
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          )}

          {couponType !== "2" && (
            <Col span={12}>
              <Form.Item
                label="Minimum Order Condition"
                name="minimumOrderCondition"
                initialValue={orderCondition}
                onChange={orderConditionHandler}
              >
                <Radio.Group>
                  <Radio value={"order_value"}>Order value</Radio>
                  {/* <Radio value={"order_quantity"}>Order quantity</Radio> */}
                </Radio.Group>
              </Form.Item>
            </Col>
          )}

          {couponType !== "2" && (
            <Col span={12}>
              <Form.Item
                label="Minimum order value"
                name="minimumOrderValue"
                rules={[
                  {
                    required: true,
                    message: "Minimum order value required !",
                  },
                ]}
              >
                <Input placeholder="Enter amount" />
              </Form.Item>
            </Col>
          )}

          {orderCondition == "order_quantity" && (
            <Col span={12}>
              <Form.Item
                label="Mimimum order quantity"
                name="minimumOrderQuantity"
                rules={[
                  {
                    required: true,
                    message: "Minimum order quantity required !",
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          )}
          {couponType == "0" && (
            <Col span={12}>
              <Form.Item label="Maximum discount" name="maximumDiscount">
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          )}

          {/* <Col span={12}>
            <Form.Item
              label="Apply coupon on"
              name="applyOn"
              initialValue={applyCouponOn}
            >
              <Select onChange={applyCouponHandler}>
                <Option value="specific_products">Specific products</Option>
                <Option value="specific_categories">Specific categories</Option>
                <Option value="all_products">All products</Option>
              </Select>
            </Form.Item>
          </Col>

          {applyCouponOn == "specific_products" && (
            <Col span={12}>
              <Form.Item
                label="Products"
                name="selectProduct"
                rules={[
                  {
                    required: true,
                    message: "Select Products !",
                  },
                ]}
              >
                <Select
                  mode="tags"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select products"
                  onChange={handleChange}
                  options={options}
                />
              </Form.Item>
            </Col>
          )}
          {applyCouponOn == "specific_categories" && (
            <Col span={12}>
              <Form.Item
                label="Category"
                name="selectCategory"
                rules={[
                  {
                    required: true,
                    message: "Select category !",
                  },
                ]}
              >
                <Select
                  mode="tags"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select category"
                  onChange={handleChange}
                  options={options}
                />
              </Form.Item>
            </Col>
          )} */}

          <Col span={24} style={{ marginTop: "4px" }}>
            <Collapse expandIconPosition="end">
              <Panel header="Coupon functionality" key="1">
                <Row
                  gutter={[16]}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography.Text>Show coupon to customer</Typography.Text>
                  <Form.Item name="showCouponToCustomer">
                    <Switch checked={true} />
                  </Form.Item>
                </Row>
                {/* <Row
                  gutter={[16]}
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography.Text>
                    Valid only for online payments
                  </Typography.Text>
                  <Form.Item name="validOnlyForOnlinePayments">
                    <Switch />
                  </Form.Item>
                </Row> */}
                {/* <Row
                  gutter={[16]}
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography.Text>
                    Valid only for new customers
                  </Typography.Text>
                  <Form.Item name="validOnlyForNewCustomers">
                    <Switch />
                  </Form.Item>
                </Row> */}
                {/* <Row
                  gutter={[16]}
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography.Text>Auto apply coupon</Typography.Text>
                  <Form.Item name="autoApplyCoupon">
                    <Switch />
                  </Form.Item>
                </Row> */}
              </Panel>
            </Collapse>
          </Col>

          <Col span={24} style={{ marginTop: "16px" }}>
            <Collapse expandIconPosition="end">
              <Panel header="Coupon validity" key="1">
                <Row
                  gutter={[16]}
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <Form.Item name="validFromDate" label="From">
                    {/* <DatePicker style={{ width: "100%" }} format={dateFormat} /> */}
                    <DatePicker
                      style={{ width: "100%" }}
                      format={dateFormat}
                      disabledDate={(current) => {
                        return current && current < dayjs().startOf("day");
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="validFromTime" label="Time">
                    <TimePicker format={format} />
                  </Form.Item>
                </Row>
                <Row gutter={[16]}>
                  <Form.Item
                    name="endDateAvailable"
                    label="Set an end data"
                    valuePropName="checked"
                  >
                    <Checkbox
                      onChange={(e) => setEndDateCheckbox(e.target.checked)}
                    />
                  </Form.Item>
                </Row>
                {endDateCheckbox && (
                  <Row
                    gutter={[16]}
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <Form.Item name="validToDate" label="From">
                      <DatePicker
                        // defaultValue={dayjs(dayjs(), dateFormat)}
                        minDate={dayjs(dayjs(), dateFormat)}
                      />
                    </Form.Item>
                    <Form.Item name="validToTime" label="Time">
                      <TimePicker
                        format="HH:mm"
                      // minTime={dayjs(dayjs(), dateFormat)}
                      />
                    </Form.Item>
                  </Row>
                )}
              </Panel>
            </Collapse>
          </Col>
        </Row>
        <div
          className="d-flex justify-content-end gap-4 pt-4"
          style={{ marginTop: "20px" }}
        >
          {id && (
            <div className="d-flex justify-content-end">
              <Button
                type="primary"
                danger
                onClick={handleDelete}
                loading={loader}
              >
                Delete
              </Button>
            </div>
          )}
          <div className="d-flex justify-content-end">
            <Button type="primary" onClick={handleSave} loading={loader}>
              Save
            </Button>
          </div>
        </div>
      </Form>
    </Card>
  );
};

export default CreateCoupon;

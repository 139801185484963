import { IconDownload } from "@tabler/icons-react";
import {
  Button,
  Card,
  Col,
  Divider,
  Row,
  Space,
  Spin,
  Table,
  Typography,
  message,
} from "antd";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../pages/sales/invoice.css";
import { apiClient } from "../../services/http.helper";
import { business_id } from "../../storage";
import { errorNotification } from "../../utils/notification.helper";
import NotFound from "../NotFound";

const DeliveryDetail = () => {
  const { delivery_id } = useParams();
  const navigate = useNavigate();
  const [businessId] = useAtom(business_id);
  const [orderProduct, setOrderProduct] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [pickupData, setPickupData] = useState(null);
  const [deliveryData, setDeliveryData] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [liveTrackingUrl, setLiveTrackingUrl] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    fetchDeliveryDetails();
  }, []);

  const fetchCurrentStatus = async (awb_code) => {
    const obj = {
      business_id: businessId,
      awb_code: awb_code,
    };
    try {
      const result = await apiClient.post(
        "api/web/shiprocket/track-status/",
        obj,
      );
      if (result.status) {
        setCurrentStatus(result?.data?.shipment_track[0]?.current_status);
        setLiveTrackingUrl(result?.data?.track_url);
        if (result.data?.error) {
          message.error(result.data.error)
        }
      } else {
        message.error("Error");
      }
    } catch (error) {
      errorNotification(
        JSON.stringify(obj),
        "DeliveryDetails",
        JSON.stringify(error),
      );
    }
  };

  const fetchDeliveryDetails = async () => {
    const obj = {
      business_id: businessId,
      delivery_id: delivery_id,
    };
    try {
      const result = await apiClient.post(
        "api/web/shiprocket/delivery-detail/",
        obj,
      );
      if (result.status) {
        setOrderProduct(result.data.order_items);
        setOrderData(result.data);
        setPickupData(result.data.warehouse);
        setDeliveryData(result.data.shiprocket_order);
        // if (!currentStatus) {
        fetchCurrentStatus(result?.data?.shiprocket_order?.awb_code);
      } else {
        message.error("Error");
      }
    } catch (error) {
      console.log(error);
      errorNotification(
        JSON.stringify(obj),
        "DeliveryDetails",
        JSON.stringify(error),
      );
    }
  };

  const handleCancelPickup = async () => {
    const obj = {
      business_id: businessId,
      delivery_id: delivery_id,
      order_id: deliveryData.order_id,
      awb_code: deliveryData.awb_code,
    };
    try {
      setLoader(true);
      const result = await apiClient.post(
        "api/web/shiprocket/cancel-shipment/",
        obj,
      );
      if (result.status) {
        fetchDeliveryDetails();
      } else {
        message.error("Error");
      }
    } catch (error) {
      errorNotification(
        JSON.stringify(obj),
        "DeliveryDetails",
        JSON.stringify(error),
      );
    } finally {
      setLoader(false);
    }
  };

  const invoiceColumns = [
    {
      render: (text, record) => (
        <div className="card">
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column">
              <div className="text-bold">{record.product_name}</div>
              <div className="text-bold fs-12">{record.product_hsn}</div>
              <div className="fs-12">
                {record.product_quantity}X{record.product_sell_price}
              </div>
              <div className="fs-12">Disc : {record.product_discount}</div>
              <div className="fs-12">Tax : {record.product_gst}</div>
            </div>
            <div>
              <div className="text-bold">₹ {record.product_amount}</div>
            </div>
          </div>
        </div>
      ),
      responsive: ["xs"],
    },
    {
      title: "#",
      render: (text, record, index) => index + 1,
      responsive: ["md"],
    },
    {
      width: "25%",
      title: "Item",
      dataIndex: "product_name",
      render: (text) => (
        <Space direction="vertical">
          <Row
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography.Text style={{ fontSize: 15, fontWeight: "bold" }}>
              {text}
            </Typography.Text>
          </Row>
        </Space>
      ),
      responsive: ["md"],
    },
    {
      title: "HSN/SAC",
      dataIndex: "product_hsn",
      responsive: ["md"],
    },
    {
      align: "right",
      title: "Quantity",
      dataIndex: "product_quantity",
      responsive: ["md"],
    },
    {
      align: "right",
      title: "Unit Price",
      dataIndex: "product_sell_price",
      render: (text) => `₹ ${text || 0}`,
      responsive: ["md"],
    },
    {
      align: "right",
      title: "Tax %",
      dataIndex: "product_gst",
      render: (text) => `${text}%`,
      responsive: ["md"],
    },
    {
      align: "right",
      title: "Tax",
      dataIndex: "product_tax",
      render: (text) => `₹ ${text || 0}`,
      responsive: ["md"],
    },
    {
      align: "right",
      title: "Net Amount",
      dataIndex: "product_amount",
      render: (text, record) => <div className="text-bold">₹ {text}</div>,
      responsive: ["md"],
    },
  ];

  const handleDownloadManifest = () => {
    window.open(deliveryData.manifest_url, "_blank");
  };

  const handleDownloadInvoice = async () => {
    if (deliveryData.invoice_url) {
      window.open(deliveryData.invoice_url, "_blank");
    } else {
      try {
        var obj = {
          business_id: businessId,
          delivery_id: deliveryData.id,
          shiprocket_order_id: deliveryData.shiprocket_order_id,
        };
        let detail_response = await apiClient.post(
          "/api/web/shiprocket/generate-invoice/",
          obj,
        );
        if (detail_response.status) {
          fetchDeliveryDetails();
          window.open(detail_response.data);
        } else {
          message.error("Error");
        }
      } catch (error) {
        errorNotification(
          JSON.stringify(obj),
          "InvoiceDetails",
          JSON.stringify(error),
        );
      }
    }
  };

  const handleDownloadLabel = async () => {
    if (deliveryData.label_url) {
      window.open(deliveryData.label_url, "_blank");
    } else {
      try {
        var obj = {
          business_id: businessId,
          delivery_id: deliveryData.id,
          shipment_id: deliveryData.shipment_id,
        };
        let detail_response = await apiClient.post(
          "/api/web/shiprocket/generate-label/",
          obj,
        );
        if (detail_response.status) {
          fetchDeliveryDetails();
          window.open(detail_response.data);
        } else {
          message.error("Error");
        }
      } catch (error) {
        errorNotification(
          JSON.stringify(obj),
          "InvoiceDetails",
          JSON.stringify(error),
        );
      }
    }
  };

  if (!delivery_id || isNaN(delivery_id)) {
    return <NotFound />;
  }
  if (!deliveryData || !orderData || !orderProduct) {
    return <Spin></Spin>;
  }

  return (
    <>
      <Row
        justify={"space-between"}
        style={{ marginTop: 10, alignItems: "center" }}
      >
        <Typography.Title level={2}>#{orderData?.order_number}</Typography.Title>
        <Space
          style={{
            display: "flex",
            justifyContent: "flex-end",
            rowGap: 20,
          }}
        >
          <Button
            className="icon-btn"
            onClick={() => handleDownloadManifest()}
            icon={<IconDownload size={16} />}
          >
            Download Manifest
          </Button>
          <Button
            className="icon-btn"
            onClick={() => handleDownloadLabel()}
            icon={<IconDownload size={16} />}
          >
            Download Label
          </Button>
          <Button
            className="icon-btn"
            onClick={() => handleDownloadInvoice()}
            icon={<IconDownload size={16} />}
          >
            Download Invoice
          </Button>
          <Button
            className="icon-btn"
            type="primary"
            disabled={deliveryData?.is_canceled === "1"}
            style={{ backgroundColor: "red" }}
            loading={loader}
            onClick={() => handleCancelPickup()}
          >
            Cancel Pickup
          </Button>
        </Space>
      </Row>

      <Space direction="vertical" className="d-flex">
        <Row gutter={[8, 8]} style={{ marginTop: 20 }}>
          <Col span={24}>
            <Space direction="vertical" style={{ display: "flex" }}>
              <Card>
                <Typography.Title level={5}>Order Detail</Typography.Title>
                <Row justify={"space-between"}>
                  <Space direction="vertical">
                    <Typography.Text style={{ fontWeight: "bold" }}>
                      Date
                    </Typography.Text>
                    <Typography.Text>
                      {dayjs(orderData?.issue_date).format("DD MMM, YYYY")}
                    </Typography.Text>
                  </Space>
                  <Space direction="vertical">
                    <Typography.Text style={{ fontWeight: "bold" }}>
                      Amount
                    </Typography.Text>
                    <Typography.Text>{orderData?.total_amount}</Typography.Text>
                  </Space>
                  <Space direction="vertical">
                    <Typography.Text style={{ fontWeight: "bold" }}>
                      Payment Mode
                    </Typography.Text>
                    <Typography.Text>{orderData?.payment_mode}</Typography.Text>
                  </Space>
                  <Space direction="vertical">
                    <Typography.Text style={{ fontWeight: "bold" }}>
                      Current Status
                    </Typography.Text>
                    {deliveryData?.is_canceled === "1" ? (
                      <Typography.Text style={{ color: "red" }}>
                        Cancelled
                      </Typography.Text>
                    ) : (
                      <Typography.Text>{currentStatus}</Typography.Text>
                    )}
                  </Space>
                  <Space direction="vertical">
                    <Typography.Text style={{ fontWeight: "bold" }}>
                      Live Tracking
                    </Typography.Text>
                    <Typography.Link href={liveTrackingUrl} target="_blank">
                      Track Here
                    </Typography.Link>
                  </Space>
                </Row>
                <Divider />
                <Row style={{ marginTop: 20 }} justify={"start"}>
                  <Col span={12}>
                    <Space direction="vertical">
                      <Typography.Text style={{ fontWeight: "bold" }}>
                        Pickup Address
                      </Typography.Text>
                      <Typography.Text>
                        {pickupData.warehouse_name}
                      </Typography.Text>
                      <Typography.Text>{pickupData.address}</Typography.Text>
                      <Typography.Text>
                        {pickupData.state}-{pickupData.pincode}
                      </Typography.Text>
                      <Typography.Text>
                        {pickupData.phone_number}
                      </Typography.Text>
                    </Space>
                  </Col>
                  <Col span={12}>
                    <Space direction="vertical">
                      <Typography.Text style={{ fontWeight: "bold" }}>
                        Shipping Address
                      </Typography.Text>
                      <Typography.Text>
                        {orderData?.customer_name}
                      </Typography.Text>
                      <Typography.Text>{orderData?.address}</Typography.Text>
                      <Typography.Text>
                        {orderData?.state}-{orderData?.pincode}
                      </Typography.Text>
                      <Typography.Text>
                        {orderData?.customer_mobile}
                      </Typography.Text>
                    </Space>
                  </Col>
                </Row>
              </Card>
              <Card>
                <Typography.Title level={5}>Courier Detail</Typography.Title>
                <Row justify={"space-between"}>
                  <Space direction="vertical">
                    <Typography.Text style={{ fontWeight: "bold" }}>
                      Courier Name
                    </Typography.Text>
                    <Typography.Text>
                      {deliveryData?.courier.name}
                    </Typography.Text>
                  </Space>
                  <Space direction="vertical">
                    <Typography.Text style={{ fontWeight: "bold" }}>
                      Pickup Date
                    </Typography.Text>
                    <Typography.Text>
                      {dayjs(deliveryData?.pickup_date).format("DD MMM, YYYY")}
                    </Typography.Text>
                  </Space>

                  <Space direction="vertical">
                    <Typography.Text style={{ fontWeight: "bold" }}>
                      AWB Code
                    </Typography.Text>
                    <Typography.Text>#{deliveryData?.awb_code}</Typography.Text>
                  </Space>
                </Row>
                <Divider />
                <Typography.Title level={5}>Package Details</Typography.Title>
                <Row justify={"space-between"}>
                  <Space direction="vertical">
                    <Typography.Text style={{ fontWeight: "bold" }}>
                      Weight(kg)
                    </Typography.Text>
                    <Typography.Text>
                      {orderData?.package_weight}
                    </Typography.Text>
                  </Space>
                  <Space direction="vertical">
                    <Typography.Text style={{ fontWeight: "bold" }}>
                      Dimensions(cm)
                    </Typography.Text>
                    <Typography.Text>
                      {orderData?.package_length} x {orderData?.package_width} x{" "}
                      {orderData?.package_height}
                    </Typography.Text>
                  </Space>
                  <Space direction="vertical">
                    <Typography.Text style={{ fontWeight: "bold" }}>
                      Volumetric weight(kg)
                    </Typography.Text>
                    <Typography.Text>
                      {(orderData?.package_length *
                        orderData?.package_width *
                        orderData?.package_height) /
                        5000}
                    </Typography.Text>
                  </Space>
                </Row>
              </Card>
              <div title={"Product Details"}>
                <Table
                  size="small"
                  columns={invoiceColumns}
                  rowKey={(record, index) => index}
                  dataSource={orderProduct}
                  pagination={false}
                  className="invoice-table invoice-item-table"
                />
              </div>
            </Space>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={24} lg={16} xl={16}>
            {/* <Card title={"Notes"}>{invoiceData.invoice_notes}</Card>

            <Card style={{ marginTop: 20 }} title={"Terms & Conditions"}>
              {invoiceData.term_condition}
            </Card> */}
          </Col>
        </Row>
      </Space>
    </>
  );
};

export default DeliveryDetail;

import React, { useEffect, useState } from 'react'
import {
    DatePicker,
    Space,
    Table,
    Button,
    Select,
} from "antd";
import { CSVLink } from "react-csv";
import { useAtom } from "jotai";
import { apiClient, ranges } from '../../../services/http.helper';
import { IconDownload, IconFilter, IconPdf } from '@tabler/icons-react';
import { business_id } from '../../../storage';
import { errorNotification } from '../../../utils/notification.helper';
const { RangePicker } = DatePicker;

const ledgerHeaders = [
    { label: "Note", key: "ledger_note" },
    { label: "Date", key: "ledger_date" },
    { label: "Credit", key: "credit" },
    { label: "Debit", key: "debit" },
    { label: "Balance", key: "balance" },
];

const transactionHeaders = [
    { label: "Date", key: "issue_date" },
    { label: "Transaction Type", key: "type" },
    { label: "Total", key: "total_amount" },
    { label: "Invoice Number", key: "invoice_number" },
    { label: "Payment Mode", key: "payment_mode" },
    { label: "Payable Balance", key: "total_payable" },
    { label: "Receivable Amount", key: "total_receivable" },
]

export default function TransactionComponent({ party }) {
    const [businessId] = useAtom(business_id);
    const [datefilter, setDatefilter] = useState();
    const [invoiceType, setInvoiceType] = useState("SALESINVOICE");
    const [partyTransactions, setPartyTransactions] = useState([])
    const columns = [
        {
            title: "Date",
            key: "1",
            dataIndex: "issue_date",
        },
        {
            title: "Transaction Type",
            key: "2",
            dataIndex: "type",
        },
        {
            title: "Total",
            key: "4",
            dataIndex: "total_amount",
        },
        {
            title: "Invoice Number",
            key: "5",
            dataIndex: "invoice_number",
        },
        {
            title: "Payment Mode",
            key: "6",
            dataIndex: "payment_mode",
        },
        {
            title: "Payable Balance",
            key: "7",
            dataIndex: "total_payable",
        },
        {
            title: "Receivable Amount",
            key: "8",
            dataIndex: "total_receivable",
        },
    ];
    const handleRangePickerChange = (dates) => {
        setDatefilter(dates);
    };
    useEffect(() => {
        const getPartyTransactions = async () => {
            try {
                var obj = {
                    party_id: party.id,
                    business_id: businessId,
                    type: invoiceType
                };
                if (datefilter) {
                    obj.filter = datefilter
                }
                const result = await apiClient.post("api/web/report/party-report", obj);
                if (result.status) {
                    const data = result.data.ledger.map((ledger) => ({ ...ledger, key: ledger.id }));
                    setPartyTransactions(result.data.ledger);
                }
            } catch (error) {
                errorNotification(
                    JSON.stringify(object),
                    "Party Transactions",
                    JSON.stringify(error)
                );
            }
        };
        getPartyTransactions();
    }, [party, businessId, invoiceType, datefilter])
    return (
        <div className="w-100 d-flex flex-column gap-10">
            <div className="d-flex flex-wrap gap-10 justify-content-between">
                <div className="d-flex flex-wrap gap-10 ">
                    <RangePicker
                        onChange={handleRangePickerChange}
                        style={{ width: 300 }}
                        className="mw-100"
                        value={datefilter}
                        presets={Object.keys(ranges).map((value, index) => ({
                            label: value,
                            value: ranges[value],
                        }))}
                    />
                    <Select
                        suffixIcon={<IconFilter size={16} />}
                        value={invoiceType}
                        onChange={(value) => setInvoiceType(value)}
                        style={{
                            width: 150,
                        }}
                        options={[
                            {
                                value: 'SALESINVOICE',
                                label: 'Sales',
                            },
                            {
                                value: 'SALESRETURN',
                                label: 'Sales Return',
                            },
                            {
                                value: 'QUOTATION',
                                label: 'Quotation',
                            },
                            {
                                value: 'DELIVERYCHALLAN',
                                label: 'Delivery Challan',
                            },
                        ]}
                    />
                </div>
                <Space>
                    <CSVLink
                        filename={`${party?.party_name}.csv`}
                        data={partyTransactions}
                        headers={transactionHeaders}
                        target="_blank"
                    >
                        <Button
                            className="icon-btn"
                            type="primary"
                            icon={<IconDownload size={16} />}
                        >
                            Download Excel
                        </Button>
                    </CSVLink>
                </Space>
            </div>
            <div className="w-100">
                <Table
                    rowKey={"id"}
                    size="small"
                    columns={columns}
                    dataSource={partyTransactions}
                    pagination={{
                        total: partyTransactions.length,
                        defaultPageSize: 5
                    }}
                    scroll={{ x: "max-content" }}
                />
            </div>
        </div>
    )
}

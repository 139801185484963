import { IconCrown } from "@tabler/icons-react";
import {
  Avatar,
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  Modal,
  Row,
  Typography,
  message,
} from "antd";
import { useAtom } from "jotai";
import React, { useState } from "react";
import * as store from "store";
import { apiClient, config } from "../services/http.helper";
import {
  business_id,
  paidUser,
  showTryModal,
  showUpgradeModal,
  sub_user,
  trialAvailable,
} from "../storage";
import { errorNotification } from "../utils/notification.helper";

const BusinessListDrawer = ({ visible, onClose }) => {
  const [businessData, setBusinessData] = useState([]);
  const [sharedBusinessData, setSharedBusinessData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [, setSelectedBusinessId] = useAtom(business_id);
  const [, setSubUser] = useAtom(sub_user);
  const [isTrial] = useAtom(trialAvailable);
  const [isPaid] = useAtom(paidUser);

  const [form] = Form.useForm();
  const [, setOpenPremium] = useAtom(showUpgradeModal);
  const [, setTryPremium] = useAtom(showTryModal);
  const handleAddNew = () => {
    if (isPaid) {
      setIsModalOpen(true);
    } else {
      if (isTrial) {
        setTryPremium(true);
      } else {
        setOpenPremium(true);
      }
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOpenChange = (value) => {
    if (value) {
      businessDataList();
    }
  };
  //Calling Create API
  const handleOk = async () => {
    try {
      var obj = form.getFieldValue();
      const result = await apiClient.post("api/web/business/create", obj);
      if (result.status) {
        message.success("Business Added Successfully!!!");
        businessDataList();
        form.resetFields();
      } else {
        message.error("Failed");
      }
    } catch (error) {
      errorNotification(
        JSON.stringify(obj),
        "BusinessListDrawer",
        JSON.stringify(error),
      );
    }
    setIsModalOpen(false);
  };
  const businessDataList = async () => {
    try {
      const result = await apiClient.get("api/web/business/");
      if (result.status) {
        setBusinessData(result.data);
        setSharedBusinessData(result.sub_user_data);
      }
    } catch (error) {
      errorNotification(
        JSON.stringify(),
        "BusinessListDrawer",
        JSON.stringify(error),
      );
    }
  };

  const onSelectBusiness = (businessId) => {
    setSelectedBusinessId(businessId);
    setSubUser(false);
    store.set("business_id", businessId);
    store.set("sub_user", false);
    onClose();
    window.location.reload();
  };
  const onSelectSharedBusiness = (businessId) => {
    setSelectedBusinessId(businessId);
    setSubUser(true);
    store.set("business_id", businessId);
    store.set("sub_user", true);
    onClose();
    window.location.reload();
  };
  return (
    <>
      <Drawer
        title="My Business"
        placement="right"
        onClose={onClose}
        open={visible}
        afterOpenChange={handleOpenChange}
        width={600}
        extra={
          <Button
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            icon={<IconCrown />}
            onClick={() => handleAddNew()}
          >
            Add New Business
          </Button>
        }
      >
        <Card style={{ justifyContent: "center" }}>
          <Row gutter={[16, 16]}>
            {businessData.map((item, index) => {
              return (
                <Col span={24} key={index}>
                  <Card
                    hoverable
                    className="cursor-pointer"
                    onClick={() => onSelectBusiness(item.id)}
                  >
                    <Card.Meta
                      avatar={
                        <Avatar
                          src={
                            config.url.STORAGE_URL +
                            "business_logo/" +
                            item.business_logo
                          }
                          shape="square"
                          style={{ height: "100%", width: "50px" }}
                        />
                      }
                      title={item.business_name}
                      description={
                        <Typography.Text ellipsis={true}>
                          {item.address}
                        </Typography.Text>
                      }
                    />
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Card>
        {!!sharedBusinessData.length && (
          <Card
            title={"Shared Business"}
            style={{ justifyContent: "center", marginTop: "20px" }}
          >
            <Row gutter={[16, 16]}>
              {sharedBusinessData.map((item, index) => {
                return (
                  <Col span={24} key={index}>
                    <Card
                      hoverable
                      className="cursor-pointer"
                      onClick={() => onSelectSharedBusiness(item.business.id)}
                      key={index}
                    >
                      <Card.Meta
                        avatar={
                          <Avatar
                            shape="square"
                            style={{ height: "100%", width: "50px" }}
                            src={
                              config.url.STORAGE_URL +
                              "business_logo/" +
                              item.business.business_logo
                            }
                          />
                        }
                        title={item.business.business_name}
                        description={
                          <Typography.Text ellipsis={true}>
                            {item.business.address}
                          </Typography.Text>
                        }
                      />
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Card>
        )}
      </Drawer>
      <Modal
        title="Add New Business"
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
      >
        <Form layout="vertical" form={form}>
          <Form.Item label="Business Name" name="business_name">
            <Input />
          </Form.Item>
          <Form.Item label="GST Number" name="gst_number">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default BusinessListDrawer;

import axios from "axios";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import { parse, stringify } from "qs";
import { useLayoutEffect } from "react";
import store from "store";
import { showPermissionDenied } from "../storage";

const dev = {
  url: {
    API_URL: "http://localhost:8000/",
    PREVIEW: 'http://localhost:8000/',
    // API_URL: "https://staging-api.billclap.com/",
    STORAGE_URL: "http://local.billclap.com/storage/",
    short_URL: "",
    WEB_URL: "",
    RAZORPAY_ID: "",
    RAZORPAY_APP_ID: "OEn2YJV29c245N",
    RAZORPAY_AUTH_REDIRECT_URI:
      "http://localhost:8001/mydukan/payments/authorize-razorpay",
  },
};

const prod = {
  url: {
    API_URL: "https://api.billclap.com/",
    PREVIEW: "https://api.billclap.com/",
    STORAGE_URL: "https://static.billclap.com/storage/",
    short_URL: "",
    WEB_URL: "",
    RAZORPAY_ID: "",
    RAZORPAY_APP_ID: "OEn2YpWQ3DYuKM",
    RAZORPAY_AUTH_REDIRECT_URI:
      "https://app.billclap.com/mydukan/payments/authorize-razorpay",
  },
};

const staging = {
  url: {
    API_URL: "https://staging-api.billclap.com/",
    short_URL: "",
    WEB_URL: "",
    RAZORPAY_ID: "",
  },
};

export const ranges = {
  "All Time": [
    dayjs("2020-01-01").month(0).startOf("month"),
    dayjs().endOf("year"),
  ],
  "Current FY": [
    dayjs().month(3).startOf("month"),
    dayjs().add(1, "year").month(2).endOf("month"),
  ],
  Today: [dayjs(), dayjs()],
  Yesterday: [dayjs().subtract(1, "days"), dayjs().subtract(1, "days")],
  "This Week": [dayjs().startOf("week"), dayjs().endOf("week")],
  "Last Week": [
    dayjs().subtract(1, "weeks").startOf("week"),
    dayjs().subtract(1, "weeks").endOf("week"),
  ],
  "This Month": [dayjs().startOf("month"), dayjs().endOf("month")],
  "Last Month": [
    dayjs().subtract(1, "months").startOf("month"),
    dayjs().subtract(1, "months").endOf("month"),
  ],
  "This Year": [dayjs().startOf("year"), dayjs().endOf("year")],
  "FY 22-23": [
    dayjs().year("2022").month(3).startOf("month"),
    dayjs().year("2023").month(2).endOf("month"),
  ],
  "FY 21-22": [
    dayjs().year("2021").month(3).startOf("month"),
    dayjs().year("2022").month(2).endOf("month"),
  ],
  "Last Quarter": [
    dayjs().subtract(3, "months").startOf("quarter"),
    dayjs().subtract(3, "months").endOf("quarter"),
  ],
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;

const http = axios.create({
  baseURL: config.url.API_URL,
  paramsSerializer: {
    encode: parse,
    serialize: stringify,
  },
  timeout: 30000,
});

// apiClient.interceptors.request.use(
//   (config) => {
//     const accessToken = store.get("user_access_token");
//     config.headers.Authorization = Bearer ${accessToken};

//     config.headers.Accept = "application/json";
//     const subUser = store.get("sub_user");
//     const businessId = store.get("business_id");
//     config.headers.SubUser = subUser ? subUser : false;
//     config.headers.BusinessId = businessId ? businessId : null;
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   },
// );

// apiClient.interceptors.response.use(
//   (response) => {
//     return parseBody(response);
//   },
//   (error) => {
//     if (error.response) {
//       if (error.response.status === 401 || error.response.status === 403) {
//         store.clearAll();
//         window.location.href = "/login";
//         return;
//       } else if (error.response) {
//         return parseError(error.response.data);
//       } else {
//         return Promise.reject(error);
//       }
//     }
//     return Promise.reject(error);
//   },
// );

// function parseBody(response) {
//   if (response.status === 200) {
//     return response.data;
//   } else {
//     return parseError(response.data);
//   }
// }
// function parseError(messages) {
//   if (messages) {
//     if (messages instanceof Array) {
//       return Promise.reject({ messages: messages });
//     } else {
//       return Promise.reject({ messages: messages });
//     }
//   } else {
//     return Promise.reject({ messages: "parse error" });
//   }
// }
// export default apiClient;

const AxiosInterceptor = () => {
  const [, setPermissionModal] = useAtom(showPermissionDenied);
  useLayoutEffect(() => {
    const requestInterceptor = http.interceptors.request.use(
      (config) => {
        const accessToken = store.get("user_access_token");
        config.headers.Authorization = `Bearer ${accessToken}`;
        config.headers.Accept = "application/json";
        const subUser = store.get("sub_user");
        const businessId = store.get("business_id");
        config.headers.SubUser = subUser ? subUser : false;
        config.headers.BusinessId = businessId ? businessId : null;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    const responseInterceptor = http.interceptors.response.use(
      (response) => {
        if (response.status === 200) {
          if (response?.data?.code === 207) {
            setPermissionModal(true);
          }
          return response.data;
        } else {
          return parseError(response.data);
        }
      },
      (error) => {
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            store.clearAll();
            window.location.href = "/login";
            return;
          } else if (error.response) {
            return parseError(error.response.data);
          } else {
            return Promise.reject(error);
          }
        }
        return Promise.reject(error);
      },
    );

    return () => {
      http.interceptors.request.eject(requestInterceptor);
      http.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return null;
};

function parseError(messages) {
  if (messages) {
    if (messages instanceof Array) {
      return Promise.reject({ messages: messages });
    } else {
      return Promise.reject({ messages: messages });
    }
  } else {
    return Promise.reject({ messages: "parse error" });
  }
}

export const apiClient = http;
export { AxiosInterceptor };

import { atom } from "jotai";
import store from "store";

const atomWithLocalStorage = (key, initialValue) => {
  const getInitialValue = () => {
    const item = store.get(key);
    if (item !== null) {
      return item;
    }
    return initialValue;
  };
  const baseAtom = atom(getInitialValue());
  const derivedAtom = atom(
    (get) => get(baseAtom),
    (get, set, update) => {
      const nextValue =
        typeof update === "function" ? update(get(baseAtom)) : update;
      set(baseAtom, nextValue);
      store.set(key, nextValue);
    },
  );
  return derivedAtom;
};

export const user_access_token = atomWithLocalStorage(
  "user_access_token",
  null,
);
export const business_id = atomWithLocalStorage("business_id", null);
export const shippingSourceAtom = atomWithLocalStorage("shippingSource", null);
export const sub_user = atomWithLocalStorage("sub_user", null);
export const fullScreenLoader = atom(false);
export const paidUser = atom(false);
export const trialAvailable = atom(false);
export const showUpgradeModal = atom(false);
export const showTryModal = atom(false);
export const themeMode = atomWithLocalStorage("theme_mode", true);
export const showPermissionDenied = atom(false);
export const tabsPrivilege = atom({
  dashboard: "1",
  parties: "1",
  products: "1",
  sales: "1",
  einvoicing: "1",
  payment: "1",
  pos: "1",
  digital_dukaan: "1",
  purchases: "1",
  expenses: "1",
  udhaar_khata: "1",
  reports: "1",
});

// export const storage =
//   "/var/www/html/billclap/storage/app/public/business_logo/";

import {
  IconBuildingBank,
  IconCheck,
  IconChevronLeft,
  IconCirclePlus,
  IconEdit,
  IconExclamationCircle,
  IconMinus,
  IconPaperclip,
  IconPlus,
  IconX,
} from "@tabler/icons-react";
import {
  Affix,
  AutoComplete,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Tooltip,
  Typography,
  message,
  theme,
  Collapse,
  Upload,
  Layout,
  Descriptions,
  Checkbox,
  Dropdown,
} from "antd";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import { debounce } from "lodash";
import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { apiClient, config } from "../../services/http.helper";
import {
  business_id,
  paidUser,
  showTryModal,
  showUpgradeModal,
  tabsPrivilege,
  trialAvailable,
} from "../../storage";
import {
  business_signature,
  chargeDeleteList,
  invoice,
  invoiceAddCharges,
  invoiceDeleteList,
  invoiceParty,
  invoiceProductList,
  invoiceSetting,
  productDummy,
  business_data,
  invoiceAttachments,
  partyClosingBalance,
  userBanksList,
} from "../../storage/invoiceStore";
import {
  fetchDocumentType,
  invoiceParams,
  paymentsModes,
  useInitializeConvertInvoice,
  useInitializeCreateInvoice,
  useInitializeEditInvoice,
  useInvoiceCalculation,
} from "../../utils/InvoiceHelperFunctions";
import {
  PermissionDeniedModal,
  PremiumModal,
  SuccessModal,
  TrialModal,
} from "../../utils/ModalHelper";
import { errorNotification } from "../../utils/notification.helper";
import useAPI from "../../utils/DataFetchHook";

import "./invoice.css";
import { Content, Footer, Header } from "antd/es/layout/layout";
import PartyLedgerModal from "../Parties/PartyLedgerModal";

const PartyForm = lazy(() => import("../Parties/PartyForm"));
const BankForm = lazy(() =>
  import('../../components/banks/BanksFormDrawer').then(module => ({
    default: module.BankForm
  }))
);
const AddcustomerComponent = lazy(
  () => import("./InvoiceComponents/AddCustomerComponent")
);
const AdditonalChargesTable = lazy(
  () => import("./InvoiceComponents/AdditionalChargesTable")
);
const InvoiceAddProductList = lazy(
  () => import("./InvoiceComponents/InvoiceAddProductList")
);
const InvoiceProductTable = lazy(
  () => import("./InvoiceComponents/InvoiceProductTable")
);

const { Panel } = Collapse;
const { Text } = Typography;
const { useToken } = theme;


const CreateInvoice = () => {
  const { token } = useToken();
  const { invoice_type, invoice_id, action } = useParams();
  const documentType = fetchDocumentType(invoice_type);
  const navigate = useNavigate();
  const [businessId] = useAtom(business_id);
  const [product_obj] = useAtom(productDummy);
  const [invoiceData, setInvoiceData] = useAtom(invoice);
  const resetInvoiceData = useResetAtom(invoice);
  const [party, setParty] = useAtom(invoiceParty);
  const resetParty = useResetAtom(invoiceParty);
  const [deleteItems] = useAtom(invoiceDeleteList);
  const resetDeleteProductList = useResetAtom(invoiceDeleteList);
  const [deleteCharges] = useAtom(chargeDeleteList);
  const resetDeleteChargeList = useResetAtom(chargeDeleteList);
  const [signature] = useAtom(business_signature);
  const [invoiceProduct, setInvoiceProductList] = useAtom(invoiceProductList);
  const resetInvoiceProductList = useResetAtom(invoiceProductList);
  const [customerDrawerVisible, setCustomerDrawerVisible] = useState(false);
  const [bankDrawer, setBankDrawer] = useState(false);
  const [additionalCharges] = useAtom(invoiceAddCharges);
  const [invoiceSettingData] = useAtom(invoiceSetting);
  const resetAdditionalCharges = useResetAtom(invoiceAddCharges);
  const [editNumber, setEditNumber] = useState(false);
  const [isChargesOpen, setChargesOpen] = useState(false);
  const [successVisible, setSuccessVisible] = useState(false);
  const [bankForm] = Form.useForm();
  const addProductListRef = useRef(null);
  const [fullyPaid, setFullyPaid] = useState(false);
  const [isPaid] = useAtom(paidUser);
  const [trialPresent] = useAtom(trialAvailable);
  const [openPremium, setOpenPremium] = useAtom(showUpgradeModal);
  const [openTryPremium, setTryPremium] = useAtom(showTryModal);
  const [editPartyId, setEditPartyId] = useState(null);
  const [partyList, setPartyList] = useState([]);
  const [businessData] = useAtom(business_data);
  const [privilege, setPrivilege] = useAtom(tabsPrivilege);
  const [isCreating, setIsCreateing] = useState(false);
  const [partyBalance, setPartyBalance] = useAtom(partyClosingBalance);
  const [isAddCustomerDrawerVisible, setIsAddCustomerDrawerVisible] = useState(false);
  const [autoCompleteInput, setAutoCompleteInput] = useState("");
  const autoCompleteRef = useRef(null);
  const [deletedAttachments, setDeletedAttachments] = useState([]);
  const [fileList, setFileList] = useAtom(invoiceAttachments);
  const [tdsOptions, setTdsOptions] = useState([]);
  const [tcsOptions, setTcsOptions] = useState([]);
  const [banksList, setBanksList] = useAtom(userBanksList);
  const PartyLedgerModalRef = useRef(null);
  const bankFormRef = useRef(null);

  const { data, loading, error } = useAPI(
    "api/web/business/detail",
    {
      business_id: businessId,
    },
    setPrivilege
  );
  const partyType =
    invoice_type === "purchase-bill" ||
      invoice_type === "debit-note" ||
      invoice_type === "purchase-order"
      ? "SUPPLIER"
      : "CUSTOMER";
  useInitializeCreateInvoice(documentType, action);
  useInitializeEditInvoice(documentType, invoice_id, action);
  useInitializeConvertInvoice(documentType, invoice_id, action);
  useInvoiceCalculation(
    invoiceProduct,
    additionalCharges,
    invoiceData,
    setInvoiceData,
    fullyPaid
  );

  const resetInvoice = () => {
    resetDeleteChargeList();
    resetAdditionalCharges();
    resetDeleteProductList();
    resetInvoiceData();
    resetInvoiceProductList();
    resetParty();
    setPartyBalance(0);
  };


  useEffect(() => {
    fetchTdsSections("tds")
    fetchTdsSections("tcs")
  }, [])

  const fetchTdsSections = async (type) => {
    const obj = {
      type: type
    }
    const result = await apiClient.post('/api/web/invoice/tds-section', obj);
    if (result.status) {
      if (type == 'tds') {
        // const tds = result.data.map((item, index) => ({
        //   key: item.id, value: item.id, label: `${item.tax}% ${item.section} ${item.name}`, data: item,
        // }));
        setTdsOptions(result.data)
      }
      else {
        setTcsOptions(result.data)
      }
    }
  }


  const openPartyLedgerModal = (partyId) => {
    PartyLedgerModalRef.current?.openModal(partyId);
  };

  const saveInvoice = async () => {
    if (!isPaid && action !== "edit") {
      if (trialPresent) {
        setTryPremium(true);
      } else {
        setOpenPremium(true);
      }
      return;
    }
    if (!party.id && !autoCompleteInput) {
      message.warning("Please Select Customer!!!");
      return;
    } else if (invoiceProduct.length < 1) {
      message.warning("Please Add Products!!!");
      openAddProductList();
      return;
    }

    try {
      setIsCreateing(true);
      const item = invoiceProduct;

      for (let i in item) {
        item[i].category = "";
        item[i].unit = "";
      }
      var obj = invoiceData;
      obj.business_id = businessId;
      if (!obj.received_amount) {
        obj.payment_mode = "";
      }
      obj.issue_date = dayjs(invoiceData.issue_date).format("YYYY-MM-DD");
      if (obj.due_date !== null) {
        obj.due_date = dayjs(invoiceData.due_date).format("YYYY-MM-DD");
      }
      obj.type = documentType;
      obj.item = item;
      obj.deleted_items = deleteItems;
      if (action === "edit") {
        obj.invoice_id = obj.id;
      } else {
        delete obj.id;
        delete obj.invoice_id;
      }
      obj.additional_charges = additionalCharges;
      obj.delete_charges = deleteCharges;
      obj.web = true;
      obj.party_id = party.id;
      if (!party.id && autoCompleteInput) {
        obj.create_party = true;
        obj.new_party_name = autoCompleteInput;
      } else {
        obj.create_party = false;
      }
      obj.attachments = fileList.map((item) => ({
        id: item.id,
        file_name: item.file_name,
        file_original_name: item.file_original_name,
      }));
      obj.deletedAttachments = deletedAttachments;
      let invoice_response = await apiClient.post(
        "/api/web/invoice/store",
        obj
      );
      if (invoice_response.status) {
        setSuccessVisible(true);
        const timeoutId = setTimeout(() => {
          resetInvoice();
          setSuccessVisible(false);
          navigate(
            `/document/${invoice_type}/details/${invoice_response.data.id}`
          );
        }, 2000);
      } else {
        message.error(invoice_response.message);
      }
    } catch (error) {
      errorNotification(
        JSON.stringify(obj),
        "CreateInvoice",
        JSON.stringify(error)
      );
    } finally {
      setIsCreateing(false);
    }
  };

  const toggleAdditionlCharges = () => {
    setChargesOpen(!isChargesOpen);
  };

  const openAddProductList = () => {
    addProductListRef.current?.onOpen();
  };

  const handleFullyPaidChange = (value) => {
    setFullyPaid(value);
    if (value) {
      setInvoiceData((invoiceData) => ({
        ...invoiceData,
        received_amount: invoiceData.total_amount,
      }));
    } else {
      setInvoiceData((invoiceData) => ({
        ...invoiceData,
        received_amount: 0,
      }));
    }
  };

  const onIssueDateChange = (date) => {
    setInvoiceData((invoiceData) => ({
      ...invoiceData,
      issue_date: date,
    }));
  };

  const onDueDateChange = (date) => {
    setInvoiceData((invoiceData) => ({
      ...invoiceData,
      due_date: date,
    }));
  };


  const closeCustomerDrawer = () => {
    setCustomerDrawerVisible(false);
  };



  const searchParty = async (search_text) => {
    try {
      const obj = {
        filters: {
          party_name: search_text,
          party_type: partyType,
        },
        business_id: businessId,
      };
      const result = await apiClient.post("api/web/parties", obj);
      if (result.status) {
        setPartyList(
          result.data.map((ele, index) => ({
            key: ele.id,
            value: ele.id,
            label: renderPartyLable(ele),
            data: ele,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const renderPartyLable = (party_data) => {
    return (
      <div className="d-flex justify-content-between flex-wrap">
        <div
          style={{
            wordBreak: "normal",
            display: "block",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {party_data.party_name}
        </div>
        <div
          style={{
            flexShrink: 0,
            color: party_data.balance?.balance >= 0 ? "green" : "red",
          }}
        >
          <span>₹ {party_data.balance?.balance}</span>
        </div>
      </div>
    );
  };
  const renderDropdown = (menu) => {
    return (
      <>
        {/* Render the default menu */}
        {menu}
        {/* Add a custom button at the bottom */}
        <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
          <Button
            type="link"
            className="icon-btn justify-content-start"
            style={{ fontWeight: "bold" }}
            block
            icon={<IconCirclePlus size={16} style={{ fontWeight: "bold" }} />}
            onClick={openAddParty}
          >
            Add {partyType === "CUSTOMER" ? "Customer" : "Vendor"}
          </Button>
        </div>
      </>
    );
  };

  const onSelectParty = (value, options) => {
    setParty(options.data);
    setPartyBalance(options?.data?.balance?.balance);
  };
  const clearSelectedCustomer = () => {
    setParty({});
    setPartyBalance(0);
    setAutoCompleteInput(null);
    setEditPartyId(null);
  };
  const openAddParty = () => {
    setIsAddCustomerDrawerVisible(true);
  };
  const closeAddParty = () => {
    setIsAddCustomerDrawerVisible(false);
  };
  const onAddNewParty = (item) => {
    setParty(item);
    closeAddParty();
  };
  const onEditCustomer = () => {
    setEditPartyId(party.id);
    openAddParty();
  };
  const onAutoCompleteInputChange = (text) => {
    setAutoCompleteInput(text);
  };
  const addNewItemRow = () => {
    const newObj = { ...product_obj };
    setInvoiceProductList((prev) => [...prev, newObj]);
  };
  const handleAttachementUpload = async (options, type) => {
    try {
      const formData = new FormData();
      formData.append("file", options.file);
      const result = await apiClient.post(
        "api/web/invoice/upload-attachment",
        formData
      );
      if (result.status) {
        setFileList((fileList) => [
          ...fileList,
          {
            uid: `-${fileList.length + 1}`,
            name: result.data.originalname,
            status: "done",
            url:
              config.url.STORAGE_URL +
              "invoice_attachments/" +
              result.data.filename,
            file_name: result.data.filename,
            file_original_name: result.data.originalname,
          },
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const removeAttachment = (file) => {
    if (file.id) {
      setDeletedAttachments((prev) => [...prev, file.id]);
    }
    setFileList((prev) => prev.filter((item) => item.uid !== file.uid));
  };
  const beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 3;
    if (!isLt2M) {
      message.error("File must smaller than 3MB!");
    }
    return isLt2M;
  };

  const onChangeTdsHandler = (data) => {
    if (data.is_tds == "1") {
      setInvoiceData((invoiceData) => ({
        ...invoiceData,
        tds_details: { ...data, is_tds: "1" },
        tcs_details: null,
      }));
    }
    else {
      setInvoiceData((invoiceData) => ({
        ...invoiceData,
        tcs_details: { ...data, is_tcs: "1" },
        tds_details: null,
      }));
    }
  }

  const openBanKFrom = (e) => {
    e.stopPropagation();
    bankFormRef.current?.openDrawer();
  }
  const updateBankList = (data) => {
    setBanksList(prev => [...prev, { key: data.id, value: data.id, label: `${data.bank_name} (${data.account_no})`, data: data }]);
    if (data.isDefault === "1") {
      setInvoiceData(prev => ({ ...prev, bank_id: data.id }));
    }
  }

  const updatePaymentModeBank = (value) => {
    const bank = banksList.find((item) => item.data.id === value);
    if (bank) {
      setInvoiceData(prev => ({ ...prev, payment_bank: bank.data }));
      if (bank.data.type === 'Cash') {
        setInvoiceData(prev => ({ ...prev, payment_mode: bank.data.type }));
      }
    }
  }

  const updatePaymentMode = (value) => {
    let bank;
    if (value === "Cash") {
      bank = banksList.find((item) => item.data.type === 'Cash');
    } else {
      bank = banksList.find((item) => item.data.id === invoiceData?.bank_id);
    }
    if (bank) {
      setInvoiceData(prev => ({ ...prev, payment_bank: bank.data }));
    }
    setInvoiceData(prev => ({ ...prev, payment_mode: value }));
  };

  const updateInvoiceBank = (value) => {
    setInvoiceData(prev => ({ ...prev, bank_id: value }));
    const bank = banksList.find((item) => item.data.id === value);
    if (bank) {
      if (bank.data.type !== "Cash") {
        setInvoiceData(prev => ({
          ...prev,
          account_holder_name: bank.data.account_holder_name,
          account_number: bank.data.account_no,
          account_type: bank.data.account_type,
          bank_name: bank.data.bank_name,
          ifsc_code: bank.data.ifsc_code,
          upi_id: bank.data.upi_id,
        }));
      } else {
        setInvoiceData(prev => ({
          ...prev,
          account_holder_name: "",
          account_number: "",
          account_type: "",
          bank_name: "",
          ifsc_code: "",
          upi_id: "",
        }));
      }
    }
  }
  return (
    <div className="layout__grayBackground">
      <Layout>
        <Header
          style={{
            position: "sticky",
            top: 0,
            background: "white",
            zIndex: 10,
            boxShadow: "0px 1px #e8e8ed",
          }}
        >
          <div className="invoice-header-topbar">
            <div className="d-flex align-item-center justify-content-center">
              <Link className="d-flex" to={`/document/${invoice_type}`}>
                <IconChevronLeft size={28} />
              </Link>
              <div>
                <Typography.Title
                  className="d-block"
                  style={{ margin: 0, padding: 0, lineHeight: 0.8 }}
                  level={4}
                >
                  {action !== "edit"
                    ? invoiceParams[
                      invoice_type.replace("-", "_", invoice_type)
                    ].create
                    : invoiceParams[
                      invoice_type.replace("-", "_", invoice_type)
                    ].update}
                </Typography.Title>
                <Typography.Text className="d-block">
                  {businessData.business_name}
                </Typography.Text>
              </div>
            </div>
            <Space>
              <Button size="middle" onClick={() => navigate(-1)}>
                Cancel
              </Button>
              <Button
                size="middle"
                type="primary"
                loading={isCreating}
                onClick={() => saveInvoice()}
              >
                Save
              </Button>
            </Space>
          </div>
        </Header>
        <Content style={{ marginTop: "30px" }}>
          <Card className="ant-card-layout">
            <Space direction="vertical" style={{ display: "flex" }}>
              <Row gutter={16} justify={"space-between"}>
                <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                  <div className="d-flex justify-content-between align-item-center mb-2">
                    <div className="d-flex align-item-center">
                      <Typography.Text>{`${partyType === "CUSTOMER" ? "Customer" : "Vendor"} Details`}</Typography.Text>
                      <Button
                        type="link"
                        className="icon-btn"
                        onClick={openAddParty}
                        style={{ fontWeight: 700 }}
                        icon={
                          <IconCirclePlus
                            size={16}
                            style={{ fontWeight: 700 }}
                          />
                        }
                      >
                        Add {partyType === "CUSTOMER" ? "Customer" : "Vendor"}
                      </Button>
                    </div>
                  </div>
                  <Card
                    className="ant-card-document"
                    styles={{ body: { backgroundColor: "#e6f2ff" } }}
                  >
                    {!party.id ? (
                      <Space
                        direction="vertical"
                        style={{ width: "100%", display: "flex" }}
                      >
                        <Space style={{ lineHeight: 0 }}>
                          <Typography.Text className="label">
                            Select{" "}
                            {partyType === "CUSTOMER" ? "Customer" : "Vendor"}
                          </Typography.Text>
                          <Tooltip
                            placement="topLeft"
                            title={`Search your existing customers using the search box below.
Please click on Add new customer to add a new Customer.`}
                          >
                            <IconExclamationCircle size={16} />
                          </Tooltip>
                        </Space>
                        <AutoComplete
                          style={{
                            width: "100%",
                          }}
                          notFoundContent="No Party found"
                          ref={autoCompleteRef}
                          value={autoCompleteInput}
                          optionLabelProp="label"
                          onSelect={onSelectParty}
                          options={partyList}
                          placeholder="Search Customer/Vendor/Company"
                          onSearch={debounce(searchParty, 500)}
                          dropdownRender={renderDropdown}
                          onChange={onAutoCompleteInputChange}
                          onFocus={() => searchParty("")}
                        ></AutoComplete>
                      </Space>
                    ) : (
                      <>
                        <div className="d-flex justify-content-between mb-2">
                          <div className="d-flex">
                            <Typography.Text
                              className="fw-600"
                              style={{ fontSize: 16 }}
                            >
                              {party.party_name}
                            </Typography.Text>
                            <Button
                              className="icon-btn"
                              size="small"
                              onClick={onEditCustomer}
                              style={{ marginLeft: 10, flexShrink: 0 }}
                              icon={<IconEdit size={12} />}
                            />
                            <Button
                              className="icon-btn"
                              danger
                              size="small"
                              onClick={clearSelectedCustomer}
                              style={{ marginLeft: 10, flexShrink: 0 }}
                              icon={<IconX size={12} />}
                            />
                          </div>
                          <Button
                            onClick={() => openPartyLedgerModal(party.id)}
                            style={{
                              borderRadius: "17px",
                              paddingInlineStart: "15px",
                              marginTop: "5px",
                            }}
                          >
                            View Ledger
                          </Button>
                        </div>
                        <Descriptions
                          className="custom-description"
                          bordered
                          labelStyle={{ padding: "8px 16px" }}
                          contentStyle={{ padding: "8px 16px" }}
                          items={[
                            {
                              key: "mobile",
                              label: "Mobile No",
                              children: party.mobile_no,
                              span: 5,
                            },
                            {
                              key: "gstin",
                              label: "GSTIN",
                              children: party.gst_no,
                              span: 5,
                            },
                            {
                              key: "state",
                              label: "State",
                              children: party.billing_address_state,
                              span: 5,
                            },
                            {
                              key: "pincode",
                              label: "Pincode",
                              children: party.billing_address_pincode,
                              span: 5,
                            },
                            {
                              key: "address",
                              label: "Address",
                              children: party.billing_address_desc,
                              span: 5,
                            },
                          ]}
                        />
                      </>
                    )}
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                  <div className="d-flex justify-content-between align-item-center mb-2">
                    <div className="d-flex align-item-center mt-10">
                      <Typography.Text>Other Details</Typography.Text>
                    </div>
                  </div>
                  <Card
                    className="ant-card-document"
                    style={{ backgroundColor: "#f9f0ff", minHeight: "6rem" }}
                  >
                    <Row gutter={[10, 10]}>
                      <Col xs={12} sm={12} md={8} lg={8}>
                        <Text className={["label", "bold"]}>Document No.</Text>
                        {editNumber ? (
                          <Input
                            style={{ width: "100%" }}
                            value={invoiceData.invoice_number}
                            onChange={(e) => {
                              setInvoiceData((invoiceData) => ({
                                ...invoiceData,
                                invoice_number: e.currentTarget.value,
                              }));
                            }}
                            suffix={
                              <IconCheck
                                size={16}
                                onClick={() => setEditNumber(false)}
                              />
                            }
                          />
                        ) : (
                          <Text className="d-flex align-item-center">
                            #{invoiceData.invoice_number}
                            <IconEdit
                              size={16}
                              color="blue"
                              style={{ marginLeft: 20 }}
                              onClick={() => setEditNumber(true)}
                            />
                          </Text>
                        )}
                      </Col>

                      <Col xs={12} sm={12} md={8} lg={8}>
                        <Text className={["label", "bold"]}>Issue Date</Text>
                        <DatePicker
                          style={{ width: "100%" }}
                          format={"DD-MM-YYYY"}
                          allowClear={false}
                          value={dayjs(invoiceData.issue_date)}
                          onChange={onIssueDateChange}
                        />
                      </Col>
                      <Col
                        hidden={invoiceSettingData.show_due_date === "0"}
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                      >
                        <Text className={["label", "bold"]}>Due Date</Text>
                        <DatePicker
                          style={{ width: "100%" }}
                          format={"DD-MM-YYYY"}
                          allowClear={false}
                          value={dayjs(invoiceData.due_date)}
                          onChange={onDueDateChange}
                        />
                      </Col>
                      <Col
                        hidden={invoiceSettingData.po_number === "0"}
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                        style={{ paddingRight: "10px" }}
                      >
                        <Text className={["label", "bold"]}>
                          {invoiceSettingData.custom_ponumber_name ||
                            "Po. Number"}
                        </Text>
                        <Input
                          value={invoiceData.po_number}
                          onChange={(e) => {
                            setInvoiceData((invoiceData) => ({
                              ...invoiceData,
                              po_number: e.currentTarget.value,
                            }));
                          }}
                        />
                      </Col>
                      <Col
                        hidden={invoiceSettingData.vehical_number === "0"}
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                        style={{ paddingRight: "10px" }}
                      >
                        <Text className={["label", "bold"]}>
                          {invoiceSettingData.custom_vehicalnumber_name ||
                            "Vehicle No."}
                        </Text>
                        <Input
                          value={invoiceData.vehical_number}
                          onChange={(e) => {
                            setInvoiceData((invoiceData) => ({
                              ...invoiceData,
                              vehical_number: e.currentTarget.value,
                            }));
                          }}
                        />
                      </Col>
                      <Col
                        hidden={invoiceSettingData.eway_bill_number === "0"}
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                      >
                        <Text className={["label", "bold"]}>
                          {invoiceSettingData.custom_ewaybill_name ||
                            "E-way Bill No."}
                        </Text>
                        <Input
                          value={invoiceData.eway_bill_number}
                          onChange={(e) => {
                            setInvoiceData((invoiceData) => ({
                              ...invoiceData,
                              eway_bill_number: e.currentTarget.value,
                            }));
                          }}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <Card styles={{ body: { backgroundColor: "#e6f2ff" } }}>
                <Row>
                  <Col span={24}>
                    {/* <div className="d-flex justify-content-end">
                <Button
                  type="primary"
                  icon={<IconPlus size={16} />}
                  className="icon-btn"
                  onClick={() => openAddProductList()}
                >
                  Add In Bulk
                </Button>
              </div> */}
                    <Suspense fallback={<Spin size="small" />}>
                      <InvoiceProductTable
                        invoiceSettingData={invoiceSettingData}
                      />
                    </Suspense>
                  </Col>
                </Row>

                <Row style={{ marginTop: 10 }}>
                  <Button
                    type="primary"
                    style={{ marginRight: 10 }}
                    icon={<IconPlus size={16} />}
                    className="icon-btn"
                    onClick={() => addNewItemRow()}
                  >
                    Add New Row
                  </Button>
                  <Button
                    type="text"
                    icon={<IconPlus size={16} />}
                    className="icon-btn"
                    onClick={() => openAddProductList()}
                  >
                    Add In Bulk
                  </Button>
                </Row>
              </Card>
              <Card>
                <Row style={{ marginTop: 20 }}>
                  <Button
                    block
                    className="icon-btn"
                    onClick={() => toggleAdditionlCharges()}
                    icon={
                      !isChargesOpen ? (
                        <IconPlus size={16} />
                      ) : (
                        <IconMinus size={16} />
                      )
                    }
                  >
                    Additional Charges
                  </Button>
                </Row>

                <AdditonalChargesTable
                  isChargesOpen={isChargesOpen}
                  onClose={() => setChargesOpen(false)}
                  onOpen={() => setChargesOpen(true)}
                />
              </Card>
              <Row gutter={[16, 16]}>
                <Col xs={24} md={12} lg={12}>
                  <Space direction="vertical" className="w-100">

                    <div style={{ marginTop: 10 }}>
                      <Space direction="vertical" className="w-100">
                        <Collapse className="notes" expandIconPosition="right">


                          <Panel className="notes" header="Notes" key="1">
                            <Input.TextArea value={invoiceData?.invoice_notes || ``} rows={5} placeholder="Enter Notes" onChange={(e) => setInvoiceData((invoiceData) => ({
                              ...invoiceData,
                              invoice_notes: e.target.value,
                            }))} />
                          </Panel>
                        </Collapse>

                        <Collapse expandIconPosition="right">
                          <Panel
                            className="terms"
                            header="Terms & Conditions"
                            key="2"
                          >
                            <Input.TextArea value={invoiceData?.term_condition || ``} rows={5} placeholder="Enter Terms & Conditions" onChange={(e) => setInvoiceData((invoiceData) => ({
                              ...invoiceData,
                              term_condition: e.target.value,
                            }))} />
                          </Panel>
                        </Collapse>
                      </Space>
                    </div>
                    <div className="mt-10 d-flex flex-column">
                      <Space className="mb-2" style={{ lineHeight: 0 }}>
                        <Typography.Text>Attach files</Typography.Text>
                        <Tooltip title="You can attach up to 3 files (3 MB each) to each transaction you create.">
                          <IconExclamationCircle size={16} />
                        </Tooltip>
                      </Space>
                      <Upload
                        beforeUpload={beforeUpload}
                        fileList={fileList}
                        customRequest={(options) =>
                          handleAttachementUpload(options, "attachments")
                        }
                        maxCount={3}
                        onRemove={removeAttachment}
                      >
                        <Button
                          disabled={fileList.length >= 3}
                          className="icon-btn"
                          icon={<IconPaperclip size={16} />}
                        >
                          Attach Files (MAX 3)
                        </Button>
                      </Upload>
                    </div>
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "10px",
                      padding: "6px",
                    }}
                  >
                    <Text
                      className="label"
                      style={{
                        fontStyle: "italic",
                        fontSize: "12px",
                        color: "gray",
                        marginRight: "4px",
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      {/* {`Apply TDS, TCS to your ${documentType == "SALESINVOICE" ? "Invoice": "Sales Return / Credit Note"}`} */}
                      {`Apply TDS `}
                    </Text>
                    <Checkbox
                      checked={invoiceData.is_tds == "1"}
                      onChange={(e) => {
                        if (invoiceData.is_tcs == "1") {
                          return message.error("TCS and TDS cannot be applied together")
                        }
                        setInvoiceData((invoiceData) => ({
                          ...invoiceData,
                          is_tds: e.target.checked ? "1" : "0",
                          tds_details: null
                        }));
                      }}
                    > TDS </Checkbox>
                    <Checkbox
                      checked={invoiceData.is_tcs == "1"}
                      onChange={(e) => {
                        if (invoiceData.is_tds == "1") {
                          return message.error("TCS and TDS cannot be applied together")
                        }
                        setInvoiceData((invoiceData) => ({
                          ...invoiceData,
                          is_tcs: e.target.checked ? "1" : "0",
                          tcs_details: null
                        }));
                      }}
                    > TCS </Checkbox>
                  </div>

                  {(invoiceData.is_tds == "1" || invoiceData.is_tcs == "1") ? (
                    <>
                      <Text className="label">{`Apply ${invoiceData.is_tds == "1" ? "TDS" : "TCS"} to your Invoice`}</Text>
                      <Card styles={{ body: { backgroundColor: "#e7f3ed", padding: '10px' } }}>
                        <Row>
                          <Col
                            span={24}
                            style={{
                              display: "inline-flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: '10px'
                            }}
                          >
                            <Select
                              style={{ width: "300px" }}
                              placeholder={`Select ${invoiceData.is_tds == "1" ? "TDS" : "TCS"} Section`}
                              value={invoiceData.is_tds == "1" && invoiceData.tds_details != null ?
                                `${invoiceData.tds_details?.tax || ""}% ${invoiceData.tds_details?.section || ""} ${invoiceData.tds_details?.name || ""}`
                                : invoiceData.is_tcs == "1" && invoiceData.tcs_details != null ?
                                  `${invoiceData.tcs_details?.tax, "%" || ""} ${invoiceData.tcs_details?.section || ""} ${invoiceData.tcs_details?.name || ""}`
                                  : null}
                              onSelect={(value, option) => onChangeTdsHandler(option.data)}
                            >
                              {invoiceData.is_tds == "1"
                                ? tdsOptions.map((data, index) => (
                                  <Select.Option key={data.tds_id} value={data.tds_id} data={data}>
                                    <span style={{ fontWeight: '700' }}>{`${data.tax}% ${data.section}`}</span>
                                    <span style={{ marginLeft: "10px", color: 'gray' }}>{data.name}</span>
                                  </Select.Option>
                                  // <Select.Option key={index} value={data.value}>{data.label}</Select.Option>
                                )) : null}
                              {invoiceData.is_tcs == "1"
                                ? tcsOptions.map((data) => (
                                  <Select.Option key={data.tds_id} value={data.tds_id} data={data}>
                                    <span style={{ fontWeight: '700' }}>{`${data.tax}% ${data.section}`}</span>
                                    <span style={{ marginLeft: "10px", color: 'gray' }}>{data.name}</span>
                                  </Select.Option>
                                )) : null}
                            </Select>
                            {invoiceData.is_tcs == "1" ?
                              <Select
                                style={{ width: "200px" }}
                                defaultValue={'total_amount'}
                                value={invoiceData?.tcs_details?.apply_on}
                                onChange={(value) => (
                                  setInvoiceData((invoiceData) => ({
                                    ...invoiceData,
                                    tcs_details: {
                                      ...invoiceData.tcs_details,
                                      apply_on: value
                                    }
                                  }))
                                )}
                                options={[
                                  {
                                    label: "Total Amount",
                                    value: "total_amount",
                                  },
                                  {
                                    label: "Net Amount",
                                    value: "net_amount",
                                  },
                                ]}
                              /> : null}
                            {invoiceData.is_tds == "1" ? <InputNumber
                              value={invoiceData.tds_details?.tds_amount || 0}
                              style={{ width: "100px" }}
                              disabled={true}
                            /> : null}
                            {invoiceData.is_tcs == "1" ? <InputNumber
                              value={invoiceData.tcs_details?.tcs_amount || 0}
                              style={{ width: "100px" }}
                              disabled={true}
                            /> : null}
                          </Col>
                        </Row>
                      </Card>
                    </>
                  ) : null}

                  <Card styles={{ body: { backgroundColor: "#e7f3ed" } }}>
                    <Row>
                      <Col
                        span={24}
                        style={{
                          display: "inline-flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Text className="label">Extra Discount</Text>
                        <InputNumber
                          value={invoiceData.additional_discount}
                          defaultValue={0}
                          min={0}
                          onChange={(value) => {
                            setInvoiceData((invoiceData) => ({
                              ...invoiceData,
                              additional_discount: value,
                            }));
                          }}
                        />
                      </Col>
                      <Divider />
                      <Col
                        xs={12}
                        md={12}
                        lg={12}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <Text className="label">Taxable Amount</Text>
                        <Text className="label">Total Tax</Text>
                        {additionalCharges.map((item, index) => {
                          return (
                            <Text key={index} className="label">
                              {item.chargeName} @{item.tax}% Tax
                            </Text>
                          );
                        })}
                        <Text className="label">
                          Round Off
                          <Switch
                            style={{ marginInlineStart: 10 }}
                            checked={invoiceData.autoRoundOffChecked}
                            onChange={(value) =>
                              setInvoiceData((invoiceData) => ({
                                ...invoiceData,
                                autoRoundOffChecked: value,
                              }))
                            }
                            size="small"
                          />
                        </Text>
                        {invoiceData.is_tcs == "1" ? <Text className="label">TCS Amount</Text> : null}
                        <Text
                          className="label"
                          style={{ fontSize: "21px", fontWeight: 600 }}
                        >
                          Total Amount
                        </Text>
                        <Text className="label">Total Discount</Text>
                      </Col>
                      <Col
                        xs={12}
                        md={12}
                        lg={12}
                        style={{
                          textAlign: "right",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Text className="label">
                          ₹{invoiceData.taxable_amount}
                        </Text>
                        <Text className="label">₹{invoiceData.total_tax}</Text>
                        {additionalCharges.map((item, index) => {
                          return (
                            <Text key={index} className="label">
                              ₹{item.chargeValue}
                            </Text>
                          );
                        })}

                        <Text className="label">
                          ₹{invoiceData.roundOffAmount}
                        </Text>
                        {invoiceData.is_tcs == "1" ? <Text className="label">
                          ₹{invoiceData.tcs_details?.tcs_amount || 0}
                        </Text> : null}
                        <Text
                          className="label"
                          style={{ fontSize: "21px", fontWeight: 600 }}
                        >
                          ₹{invoiceData.total_amount}
                        </Text>
                        <Text className="label">
                          ₹{invoiceData.total_discount}
                        </Text>
                      </Col>
                    </Row>
                  </Card>
                  <div className="d-flex justify-content-between mt-10  align-item-center">
                    <span>Select Bank</span>
                    <Button type="link" className="text-left icon-btn" icon={<IconCirclePlus size={16} />} onClick={(e) => openBanKFrom(e)}>
                      <span className="font-weight-bold">Add New Bank</span>
                    </Button>
                  </div>
                  <Card styles={{ body: { backgroundColor: "rgb(249, 240, 255)" } }}>
                    {banksList.filter((bank) => bank.data.type === 'Bank').length ?

                      <Select className="w-100"
                        options={banksList}
                        value={invoiceData.bank_id}
                        onChange={updateInvoiceBank}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <div className="bg-gray-75">
                              <Button block type="link" className="text-left icon-btn justify-content-start" icon={<IconCirclePlus size={16} />} onClick={(e) => openBanKFrom(e)}>
                                <span className="font-weight-bold">Add New Bank</span>
                              </Button>
                            </div>
                          </>
                        )}
                      />
                      : <div className="d-flex justify-content-center align-item-center cursor-pointer text-danger p-20 fs-21 bg-white rounded-10" onClick={(e) => openBanKFrom(e)}>
                        <Space>
                          <IconBuildingBank size={16} />
                          <h5>Add New Bank Account</h5>
                        </Space>
                      </div>
                    }
                  </Card>
                  <Card className="mt-10" styles={{ body: { backgroundColor: "#e7f3ed" } }}>
                    <Row>
                      <Col
                        span={24}
                        hidden={
                          invoice_type === "quotations" ||
                          invoice_type === "purchase-order"
                        }
                      >
                        <Space
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: 10,
                          }}
                        >
                          <Text style={{ display: "block" }} className="label">
                            Add Payment{" "}
                          </Text>
                          <div className="d-flex">
                            <Text style={{ display: "block" }} className="label">
                              Fully Paid{" "}
                            </Text>
                            <Switch
                              style={{ marginInlineStart: 10 }}
                              checked={fullyPaid}
                              onChange={(value) => handleFullyPaidChange(value)}
                              size="small"
                            />
                          </div>
                        </Space>


                        <Space.Compact
                          block
                          style={{ display: "flex" }}
                          wrap="true"
                        >
                          <InputNumber
                            min={0}
                            wheel="false"
                            disabled={fullyPaid}
                            // value={invoiceData.received_amount}
                            value={invoiceData.received_amount - (fullyPaid ? (invoiceData.tds_details?.tds_amount || 0) : 0)}
                            // value={
                            //   !fullyPaid
                            //     ? parseFloat(invoiceData.received_amount) || 0
                            //     : invoiceData.is_tds === "1"
                            //     ? (parseFloat(invoiceData.received_amount) || 0) - (parseFloat(invoiceData.tds_details?.tds_amount) || 0)
                            //     : invoiceData.is_tcs === "1"
                            //     ? (parseFloat(invoiceData.received_amount) || 0) + (parseFloat(invoiceData.tcs_details?.tcs_amount) || 0)
                            //     : parseFloat(invoiceData.received_amount) || 0 
                            // }                          
                            onChange={(value) =>
                              setInvoiceData((invoiceData) => ({
                                ...invoiceData,
                                received_amount: value,
                              }))
                            }
                            style={{ width: "100%" }}
                          ></InputNumber>
                          <Select
                            style={{ width: 150 }}
                            value={invoiceData.payment_mode || 'Cash'}
                            onChange={updatePaymentMode}
                            defaultValue="Cash"
                            dropdownStyle={{
                              width: 200
                            }}
                            options={paymentsModes}
                          />
                        </Space.Compact>

                        <Space className="d-flex justify-content-between align-item-center">
                          <Typography.Text
                            style={{ fontWeight: 600, color: "#3062e1" }}
                          >
                            {`Balance Amount : ₹ ${invoiceData.total_amount -
                              invoiceData.received_amount}`}

                          </Typography.Text>
                          <Select
                            variant="borderless"
                            size="small"
                            disabled={invoiceData.payment_mode === 'Cash'}
                            style={{ maxWidth: 180 }}
                            value={invoiceData?.payment_bank?.id}
                            onChange={updatePaymentModeBank}
                            placeholder="Select Bank"
                            options={banksList}
                            dropdownStyle={{
                              width: 400,
                              fontSize: 12
                            }}
                          />
                        </Space>
                      </Col>
                    </Row>
                  </Card>
                  <Card
                    title={"Signature"}
                    style={{ marginTop: 8, backgroundColor: "#fafafa" }}
                  >
                    <div
                      style={{ justifyContent: "center", display: "flex" }}
                    >
                      {signature ?
                        <Image
                          width={150}
                          src={config.url.STORAGE_URL + "signature/" + signature}
                          preview={false}
                        />
                        : null}
                    </div>
                  </Card>
                </Col>
              </Row>
              <Affix offsetBottom={0}>
                <Space
                  direction="horizontal"
                  style={{
                    backgroundColor: token.invoiceFooterBgColor,
                    padding: 20,
                    borderRadius: 6,
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography.Text>
                    Grand Total :{" "}
                    <Text style={{ fontSize: "21px", fontWeight: 600 }}>
                      ₹{invoiceData.total_amount}
                    </Text>
                  </Typography.Text>
                  <Space style={{ rowGap: 40 }}>
                    <Button size="large" onClick={() => navigate(-1)}>
                      Cancel
                    </Button>
                    <Button
                      size="large"
                      type="primary"
                      loading={isCreating}
                      onClick={() => saveInvoice()}
                    >
                      Save
                    </Button>
                  </Space>
                </Space>
              </Affix>
              <InvoiceAddProductList ref={addProductListRef} />
              <AddcustomerComponent
                visible={customerDrawerVisible}
                onClose={closeCustomerDrawer}
              />

              <SuccessModal visible={successVisible} />
              <PartyForm
                visible={isAddCustomerDrawerVisible}
                type={partyType}
                onClose={closeAddParty}
                onRefresh={(party_data) => onAddNewParty(party_data)}
                partyId={editPartyId}
              />
            </Space>
          </Card>
        </Content>
        <Footer>
          <div className="layout-footer">
            <div className="d-flex">
              <Link to={`https://www.billclap.com`} target="_blank">
                <img src={`/images/billclap.png`} width={100} />
              </Link>
            </div>
            <Typography.Text className="font-size-12">
              ©{new Date().getFullYear()} Digiclap Technologies Private
              Limited. All rights reserved.
            </Typography.Text>
          </div>
        </Footer>
      </Layout>
      <PremiumModal visible={openPremium} />
      <TrialModal visible={openTryPremium} />
      <PermissionDeniedModal />
      <PartyLedgerModal ref={PartyLedgerModalRef} />
      <BankForm ref={bankFormRef} onSave={updateBankList} />
    </div>
  );
};
export default CreateInvoice;

import React, { useEffect, useState } from "react";
import NoInternet from "../pages/NoInternet";

const NoInternetConnection = (props) => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true);

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  // event listeners to update the state
  window.addEventListener("online", () => {
    setOnline(true);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });

  // if user is online, return the child component else return a custom component
  if (isOnline) {
    return props.children;
  } else {
    if (process.env.NODE_ENV !== 'development') {
      return <NoInternet />;
    }
    return props.children
  }
};

export default NoInternetConnection;

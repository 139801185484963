import { Worker } from "@react-pdf-viewer/core";
import { ConfigProvider, Descriptions, Spin, theme } from "antd";
import { useAtom } from "jotai";
import React, { Suspense } from "react";
import { CookiesProvider } from "react-cookie";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter as Router, useLocation, useSearchParams, createBrowserRouter } from "react-router-dom";
import ErrorComponent from "./pages/ErrorComponent";
import { AxiosInterceptor } from "./services/http.helper";
import { fullScreenLoader, themeMode } from "./storage";
import MainRoutes from "./utils/MainRoutes";
import NoInternetConnection from "./utils/NetworkStatusHook";
import ServerStatus from "./utils/ServerStatusHook";

import "./App.css";
import SuspenseLoader from "./utils/SuspenseLoader";

function App() {
  const [loader] = useAtom(fullScreenLoader);
  const [theme_mode] = useAtom(themeMode);
  const theme_config = {
    token: {
      colorPrimary: "#3062E1",
      colorBorder: "#e4e9f0",
      invoiceFooterBgColor: "#e4e3e3",
      colorInfo: "#2754ff",
      colorError: "#e11900",
      colorTextBase: "#000000",
      colorBorderSecondary: "#F4F4F9",
      colorBgSpotlight: "#1d1d1f",
      boxShadowSecondary: "0 0 9px rgba(0,0,0,0.1)",
      colorText: "#1d1d1f",
      colorTextSecondary: "#6e6e73",
      colorTextTertiary: "#86868b",
      colorTextQuaternary: "#AEAEB2",
      colorBgLayout: "#f5f5f7",
      fontSize: 14,
      fontFamily: "$base-font-family"
    },
    components: {
      Table: {
        cellFontSizeSM: "0.86 rem",
        cellPaddingInlineSM: 5.4,
        cellPaddingBlockSM: 9,
      },
    },
    algorithm: [theme_mode ? theme.darkAlgorithm : theme.defaultAlgorithm],
  };

  return (
    <Worker workerUrl="/js/pdf.worker.min.js">
      <ConfigProvider theme={theme_config}>
        <ErrorBoundary FallbackComponent={ErrorComponent}>
          <CookiesProvider>
            <Spin spinning={loader} fullscreen />
            <NoInternetConnection>
              <ServerStatus>
                <Router>
                  <MainRoutes />
                </Router>
              </ServerStatus>
            </NoInternetConnection>
          </CookiesProvider>
        </ErrorBoundary>
        <AxiosInterceptor />
      </ConfigProvider>
    </Worker>
  );
}

export default App;
